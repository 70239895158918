import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { toast } from "react-toastify";
import { Type } from "react-toastify/dist/utils";
import axios from "axios";
import { setAppUser } from "../../app/features/auth/AppUser";

const Profile = () => {
  const appUser = useAppSelector((state) => state.appUser);
  const dispatch = useAppDispatch();

  const [user, setUser] = useState<{
    full_name: string;
    email: string;
    phone: string;
    status: string;
    password?: string;
    password_confirmation?: string;
  }>({
    full_name: "",
    email: "",
    phone: "",
    status: "",
    password: "",
    password_confirmation: "",
  });
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    setUser((prev) => (prev = { ...appUser, full_name: appUser.name}));
  }, []);

  const validatePassword = () => {
    const valid = user?.password === user?.password_confirmation;
    if (!valid) {
      toast("Password Mismatch", {
        type: toast.TYPE.ERROR,
      });
    }
    return valid;
  };

  const updateProfileSubmit = () => {
    // console.log(validatePassword());
    if (!validatePassword()) {
      return;
    }
    let data = Object.fromEntries(
      Object.entries(user).filter(([_, v]) => v != "")
    );
    axios
      .post("/admin/change_profile", data)
      .then((res) => {
        console.log(res.data);
        toast(res.data.message, {
          type: toast.TYPE.SUCCESS,
        });
        dispatch(
          setAppUser({ ...appUser, name: user.full_name, phone: user.phone })
        );
      })
      .catch((err) => {
        console.log(err);
        toast(err.response?.data?.message, {
          type: toast.TYPE.INFO,
        });
        if (!err.response?.data?.message) {
          toast(err.message, {
            type: toast.TYPE.INFO,
          });
        }
      });
  };

  return (
    <div>
      <div>
        <Header title="Profile" />
      </div>
      <div className="px-11 py-11">
        <div className="card-shadow rounded-[10px] p-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
                <h4 className="text-lg font-bold">Update Profile</h4>
            </div>
            <div></div>
            <div>
              <label htmlFor="name" className="text-sm">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={user?.full_name}
                onChange={(e) =>
                  setUser(
                    (prev) => (prev = { ...prev, full_name: e.target.value })
                  )
                }
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
              />
            </div>
            <div></div>
            <div>
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <input
                type="text"
                name="email"
                readOnly
                value={user?.email}
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
              />
            </div>
            <div></div>
            <div>
              <label htmlFor="phone" className="text-sm">
                Phone
              </label>
              <input
                type="number"
                name="phone"
                onChange={(e) =>
                  setUser((prev) => (prev = { ...prev, phone: e.target.value }))
                }
                value={user.phone}
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
              />
            </div>
            <div></div>
            <div>
              <label htmlFor="status" className="text-sm">
                Status
              </label>
              <input
                type="text"
                name="status"
                readOnly
                value={Number(user?.status) === 1 ? "Enabled" : "Disabled"}
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
              />
            </div>
            <div></div>
            <div>
              <label htmlFor="password" className="text-sm">
                Password
              </label>
              <input
                type="password"
                name="password"
                value={user?.password}
                onChange={(e) =>
                  setUser(
                    (prev) => (prev = { ...prev, password: e.target.value })
                  )
                }
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
              />
            </div>
            <div></div>
            <div>
              <label htmlFor="confirm_password" className="text-sm">
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm_password"
                value={user?.password_confirmation}
                onChange={(e) =>
                  setUser(
                    (prev) =>
                      (prev = {
                        ...prev,
                        password_confirmation: e.target.value,
                      })
                  )
                }
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
              />
            </div>
          </div>
          <div className="py-6">
            <button
              onClick={updateProfileSubmit}
              className="h-[40px] px-6 text-white bg-cs-blue rounded-lg"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
