import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { PiCopyFill, PiPercent } from "react-icons/pi";
import axios from "axios";

const GSettings = () => {
  interface Gateway {
    gateway: {
      id: number
      code: string;
      alias: string;
      imageUrl: string;
      name: string
      status: string
      gateway_parameters: string;
      enable_payment_mode: string
      supported_currencies: string
      type: number
      extra: null;
      description: null;
      input_form: null;
      created_at: null;
      updated_at: string
      currencies: [];
    };
    supportedCurrencies: Record<string, string>;
    gateway_parameters: Record<string, parameter>;
  }
  interface parameter{
    title: string
    value: string
  }

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { alias } = useParams();
  const [dataLoading, setDataLoading] = useState(true);
  const [gateway, setGateway] = useState<Gateway>({} as Gateway);

  useEffect(() => {
    axios
      .get(`/admin/gateway/${alias}`)
      .then((res) => {
        setDataLoading(false);
        setGateway(res.data.data);
      })
      .catch((err) => {
        setDataLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div>
        <Header title="Payment Gateway" />
      </div>

      <div className="px-11 py-11">
        <div>
          <div className="card-shadow h-full px-8 pb-8">
            <div>
              <h4 className="pt-6 font-bold text-lg">Gateway</h4>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="text-sm">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  readOnly
                  value={gateway.gateway?.name}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="alias" className="text-sm">
                  Alias
                </label>
                <input
                  type="text"
                  name="alias"
                  readOnly
                  value={gateway.gateway?.alias}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="status" className="text-sm">
                  Status
                </label>
                <p
                  className={`${
                    gateway.gateway?.status === "1" ? "text-cs-blue" : "text-orange-500"
                  } w-full border-solid border-[1px] border-[#ABB3B7] rounded-3xl ps-3 p-1`}
                >
                  {gateway.gateway?.status === "1" ? "Enabled" : "Disabled"}
                </p>
              </div>
            </div>

            <div>
              <h4 className="pt-6 font-bold text-lg">Gateway Parameters</h4>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {
                gateway.gateway_parameters && Object.keys(gateway.gateway_parameters).map((param, i) => {
                  return (
                    <div>
                      <label className="text-sm">
                        {gateway.gateway_parameters[`${param}`].title}
                      </label>
                      <input
                        type="text"
                        value={gateway.gateway_parameters[`${param}`].value}
                        readOnly
                        className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                      />
                    </div>
                  );
                })
              }
            </div>

            <div>
              <h4 className="pt-6 font-bold text-lg">Payment Mode</h4>
            </div>
            <div className="max-w-[412px] grid sm:grid-cols-2 gap-4">
              {gateway.gateway?.enable_payment_mode &&
                Object.keys(
                  JSON.parse(gateway.gateway?.enable_payment_mode)
                ).map((mode, i) => {
                  return (
                    <div className="flex items-center gap-2">
                      <input
                        className="accent-cs-blue"
                        type="checkbox"
                        checked
                        readOnly
                      />
                      <label>{mode}</label>
                    </div>
                  );
                })}
            </div>

            <div>
              <h4 className="pt-6 font-bold text-lg">Supported Currency</h4>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <select
                name=""
                id=""
                className="border-solid border-[1px] border-[#ABB3B7] rounded-3xl ps-3 py-2"
              >
                {gateway.supportedCurrencies &&
                  Object.keys(gateway?.supportedCurrencies).map((curr, i) => {
                    return (
                      <option
                        key={i}
                        className="p-1"
                        value={gateway.supportedCurrencies[curr]}
                        selected={curr === "NGN"}
                      >
                        {curr}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSettings;
