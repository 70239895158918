import React, { useState, useRef, useEffect } from "react";
import { PermissionName } from "../../../interfaces";


const ModuleComponent = (props: {
  module_access: Record<PermissionName, string>;
}) => {
  const [hoverState, setHoverState] = useState(false);

  const ref: any = useRef();

  // useOutsideClick(ref.current, () => {
  //   setHoverState(false);
  // });

  const getSubsString = () => {
    let text = "";
    Object.keys(props.module_access).map((item) => {
      text = text + ", " + item;
    });
    return text.substring(1, 35);
  };
  
  return (
    <div
      onClick={() => setHoverState(!hoverState)}
      className="hover:cursor-default"
      ref={ref}
    >
      {!hoverState ? (
        <div className="hover:cursor-pointer">{getSubsString()}...</div>
      ) : (
        <div className="">
          {Object.keys(props.module_access).map((item, idx, arr) => {
            return (
              <div key={idx}>
                {item}
                {arr.length === idx + 1 ? "" : ", "}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

// function useOutsideClick(ref: any, onClickOut: () => void, deps = []) {
//   useEffect(() => {
//     const onClick = ({ target }: any) =>
//       !ref?.contains(target) && onClickOut?.();
//     document.addEventListener("click", onClick);
//     return () => document.removeEventListener("click", onClick);
//   }, deps);
// }

export default ModuleComponent;
