import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";

const SupportTickets = () => {
  const navigate = useNavigate()
  return (
    <div>
      <div>
        <Header title="Support Ticket" />
      </div>
      <div className="px-11 pb-11">
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <p className="py-4 font-bold text-xl">Details</p>
            <div className="relative inline-block">
              <input
                className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-2 rounded-3xl placeholder:text-center"
                type="text"
                placeholder="search"
              />
              <BiSearch className="absolute right-3 top-[15%]" />
            </div>
          </div>

          <div className="">
            <div className="overflow-auto lg:overflow-visible ">
              <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                <thead className="bg-[#80B1F8]">
                  <tr className="">
                    <th className="p-2">Subject</th>
                    <th className="p-2">Submitted by</th>
                    <th className="p-2">Priority</th>
                    <th className="p-2">Last Reply</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-[#fff] shadow-lg">
                    <td className="p-2">[Ticket#378892]</td>
                    <td className="p-2">test</td>
                    <td className="p-2">
                      <p className="px-2 py-1 rounded bg-red-600 text-white">
                        High
                      </p>
                    </td>
                    <td className="p-2">2 Month ago</td>
                    <td className="p-2 ">
                      <div className="flex justify-center gap-2">
                        <AiOutlineEye  onClick={()=> navigate('ticket/01')}  className="text-xl hover:text-cs-blue" />
                        <BiEditAlt  onClick={()=> navigate('ticket/01?p=edit')}  className="text-xl hover:text-[#E87001]" />
                        <RiDeleteBinLine className="text-xl" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTickets;
