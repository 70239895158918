import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface SidebarState{
  open: boolean
}

const initialState: SidebarState = {
  open: true
}

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers:{
    toggleSidebar: (state, action: PayloadAction<string>) => {
      switch(action.payload){
        case "open":
          state.open = true;
          break;
        case "close":
          state.open = false
          break;
        default:
          break;
      }
    }
  }
});

export const { toggleSidebar }  = sidebarSlice.actions;

export const selectSidebarState = (state: RootState) => state.sidebar.open

export default sidebarSlice.reducer