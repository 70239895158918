import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { AppUser, Permissions } from "../../../interfaces";

const initialState: AppUser = {
  id: undefined,
  name: "",
  email: "",
  phone: "",
  email_verified_at: "",
  role: {
    id: undefined,
    name: "",
    module_access: {} as Permissions,
    action_access: null,
    status: undefined,
    created_at: null,
    updated_at: null,
  },
  status: "",
  created_at: "",
  updated_at: "",
};

export const appUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAppUser: (state, action: PayloadAction<AppUser>) => {
      localStorage.setItem("appUser", JSON.stringify(action.payload));
      return {
        ...action.payload,
      };
    },
  },
});

export const { setAppUser } = appUserSlice.actions;

export const selectAppUser = (state: RootState) => state.appUser;

export default appUserSlice.reducer;
