import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { PiPercent, PiCopyFill } from "react-icons/pi";
import { LiaCloudUploadAltSolid } from "react-icons/lia";
import axios from "axios";
import { MoonLoader } from "react-spinners";

const DMerchant = () => {
  interface Merchant {
    id: 3;
    merchant_uuid: string;
    merchant_business_name:  string;
    merchant_site_url: string;
    about_merchant: string;
    logo: string;
    merchant_address: {
      name:  string;
      email:  string;
      document:  string;
      phone:  string;
      address: {
        country: string;
        state: string;
        city: string;
        zip_code: string;
        street: string;
        number: string;
      };
    };
    merchant_fee: {
      fee_1: {
        name: string;
        value: number
        type: string;
      };
      fee_2: {
        name: string;
        value: number
        type: string;
      };
    };
    merchant_status: "Approved";
    merchant_appInfo: {
      id: 3;
      client_id: "1564a3fb58a77ec";
      client_secret: "7eb1d780-9a74-466d-bc6c-c3a8d4023fb8";
      client_callback_url: "https://unicconvr.com/";
      client_webhook_url: "https://apis.payfocuss.com/api/v1/gateway/webhook";
    };
  }
  enum FeeType{
    GENERAL = 'percentage',
    FIXED = 'fixed'
  }
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { id } = useParams();
  const editPage = !(query.get("p") === "edit");
  const name = query.get("mht");
  const [contentLoading, setContentLoading] = useState(true);
  const [merchant, setMerchant] = useState<Merchant>();
  const [feeType, setFeeType] = useState<FeeType>(FeeType.GENERAL);

  useEffect(() => {
    axios
      .get(`admin/merchants/${id}`)
      .then((res) => {
        setMerchant(res.data.data);
        setContentLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div>
        <Header title="Merchant Details" />
      </div>

      <div className="p-11">
        {contentLoading ? (
          <div className="w-full pb-6 flex items-center justify-center">
            <MoonLoader color="#0D6EFD" size={30} />
          </div>
        ) : null}
        <div>
          <div className="card-shadow rounded-[10px] min-h-[600px] p-6 pb-6">
            <div className="font-bold pb-4 text-lg">
              <h4>
                Information of {name ? name : merchant?.merchant_business_name}
              </h4>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 gap-y-8">
              <div>
                <label htmlFor="merchantid" className="text-sm">
                  Merchant Id
                </label>
                <input
                  type="text"
                  name="merchantid"
                  value={merchant?.merchant_uuid}
                  readOnly
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="businessName" className="text-sm">
                  Business Name
                </label>
                <input
                  type="text"
                  name="businessName"
                  value={merchant?.merchant_business_name}
                  readOnly
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="siteUrl" className="text-sm">
                  Site Url
                </label>
                <input
                  type="text"
                  name="siteUrl"
                  value={merchant?.merchant_site_url}
                  readOnly
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="image" className="text-sm">
                  Logo
                </label>
                <div className="relative w-full">
                  <input
                    type="file"
                    name="image"
                    accept="image/jpeg,image/png,image/gif"
                    // onChange={(e) =>
                    //   setData({ ...data, image: e.target.files?.[0] })
                    // }
                    className="w-full hover:cursor-pointer border-solid border-[1px] border-[#ABB3B7] rounded-md text-sm ps-3 p-1 opacity-0"
                  />
                  <div className="absolute -z-10 w-full h-full flex items-center gap-2 ps-3 top-0 left-0 border-dotted border-[2px] border-[#ABB3B7] rounded-3xl text-sm">
                    <LiaCloudUploadAltSolid className="text-lg" />
                    <p className="">Logo</p>
                  </div>
                  <div className="absolute h-full w-[20%] top-0 right-0">
                    <button className="bg-cs-blue rounded-3xl w-full h-full text-sm text-white">
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-8">
              <label htmlFor="about" className="text-sm">
                About
              </label>
              <textarea
                name="about"
                id=""
                value={merchant?.about_merchant}
                readOnly={editPage}
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center p-3"
                rows={5}
              ></textarea>
            </div>

            <div className="font-bold pt-6 text-lg">
              <h4>Merchant Address</h4>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-6">
              <div>
                <label htmlFor="name" className="text-sm">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={merchant?.merchant_address.name}
                  readOnly
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="email" className="text-sm">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={merchant?.merchant_address.email}
                  readOnly
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="document" className="text-sm">
                  Document
                </label>
                <input
                  type="text"
                  name="document"
                  value={merchant?.merchant_address.document}
                  readOnly
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="phone" className="text-sm">
                  Phone
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="phone"
                    value={merchant?.merchant_address.phone}
                    readOnly
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 p-1"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 pt-6">
              <div>
                <label htmlFor="country" className="text-sm">
                  Country
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="country"
                    value={merchant?.merchant_address.address.country}
                    readOnly
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 p-1"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="state" className="text-sm">
                  State
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="state"
                    value={merchant?.merchant_address.address.state}
                    readOnly
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 p-1"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="city" className="text-sm">
                  City
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="city"
                    value={merchant?.merchant_address.address.city}
                    readOnly
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 p-1"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="zip" className="text-sm">
                  ZIP/Postal
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="zip"
                    value={merchant?.merchant_address.address.zip_code}
                    readOnly
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 p-1"
                  />
                </div>
              </div>
            </div>
            <div className="py-6">
              <label htmlFor="street_name" className="text-sm">
                Street Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="street_name"
                  value={merchant?.merchant_address.address.street}
                  readOnly
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 p-1"
                />
              </div>
            </div>

            <div className="font-bold pt-6 text-lg">
              <h4>Merchant Fee</h4>
            </div>

            <div className="pt-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 pb-2">
                <select
                  name=""
                  id=""
                  onChange={(e) => {
                    setFeeType(e.target.value as FeeType);
                  }}
                  className="border-solid border-[1px] border-[#ABB3B7] outline-none focus:outline-none rounded-3xl ps-3 py-2"
                >
                  <option className="p-1" value={FeeType.GENERAL} selected>
                    General Fee
                  </option>
                  <option className="p-1" value={FeeType.FIXED}>
                    Fixed Fee
                  </option>
                </select>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 pt-2">
                <div className="relative">
                  <input
                    type="text"
                    name=""
                    value={
                      feeType === FeeType.GENERAL
                        ? merchant?.merchant_fee.fee_1.value
                        : merchant?.merchant_fee.fee_2.value
                    }
                    readOnly={editPage}
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 p-1 pe-[10%]"
                  />
                  <div className="absolute border-l-[1px] border-zinc-400 right-0 top-0 h-[100%] w-[10%] flex justify-center items-center text-sm">
                    {feeType === FeeType.GENERAL ? (
                      <PiPercent className="" />
                    ) : (
                      <p>NGN</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="font-bold pt-6 text-lg">
              <h4>Merchant App Info</h4>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-6">
              <div>
                <label htmlFor="clientId" className="text-sm">
                  Client ID
                </label>
                <div className="relative">
                  <input
                    type="text"
                    name="clientId"
                    value={merchant?.merchant_appInfo.client_id}
                    readOnly
                    className="w-full border-solid border-[2px] bg-[#ABB3B7] border-[#ABB3B7] rounded-3xl placeholder:text-center ps-3 p-1 pe-[10%]"
                  />

                  <div className="absolute border-l-[1px] border-zinc-500 right-0 top-0 h-[100%] w-[10%] flex justify-center items-center">
                    <PiCopyFill className="" />
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="secretkey" className="text-sm">
                  Secret Key
                </label>
                <div className="relative">
                  <input
                    type="text"
                    name="secretkey"
                    value={merchant?.merchant_appInfo.client_secret}
                    readOnly
                    className="w-full border-solid border-[2px] bg-[#ABB3B7] border-[#ABB3B7] rounded-3xl placeholder:text-center ps-3 p-1 pe-[10%]"
                  />
                  <div className="absolute border-l-[1px] border-zinc-500 right-0 top-0 h-[100%] w-[10%] flex justify-center items-center">
                    <PiCopyFill className="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="callbackurl" className="text-sm">
                    Callback URL
                  </label>
                  <input
                    type="text"
                    name="callbackurl"
                    value={merchant?.merchant_appInfo.client_callback_url}
                    readOnly
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                  />
                </div>
                <div>
                  <label htmlFor="webhookurl" className="text-sm">
                    Webhook URL
                  </label>
                  <input
                    type="text"
                    name="webhookurl"
                    value={merchant?.merchant_appInfo.client_webhook_url}
                    readOnly
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DMerchant;
