import React, { useState } from "react";
import { PermissionName } from "../../../interfaces";

const Ptag = (props: {
  name: PermissionName;
  value: string;
  clickPermission: (pname: PermissionName, pvalue: string, init: boolean) => void;
}) => {
  const [clickState, setClickState] = useState(false);
  function handleOnClick() {
    props.clickPermission(props.name, props.value, clickState)
    setClickState(!clickState);
  }

  return (
    <div>
      <div
        onClick={() => handleOnClick()}
        className={`px-2 border-solid border-[1.5px] whitespace-pre border-cs-blue rounded text-sm hover:cursor-pointer ${
          clickState ? "bg-cs-blue text-white" : ""
        }`}
      >
        {props.value}
      </div>
    </div>
  );
};

export default Ptag;
