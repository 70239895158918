import React from "react";
import { useAppSelector } from "../../app/store";
import { Outlet } from "react-router-dom";
import { Route } from "../../interfaces";

const PermissionProtected = (props: { route: Route }) => {
  const user = useAppSelector((state) => state.appUser);
  const authorized = Object.keys(user.role.module_access).includes(
    props.route.permission as string
  );

  return  authorized || props.route.path === "profile" ? <Outlet /> : null;
};

export default PermissionProtected;
