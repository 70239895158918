import React from "react";
import Header from "../../components/Header";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HiReply } from "react-icons/hi";
import { BsPlusLg } from "react-icons/bs";
import { FiFilePlus, FiTrash2 } from "react-icons/fi";

const Ticket = () => {
  return (
    <div>
      <div>
        <Header title="Support Tickets" />
      </div>
      <div className="px-11 pb-11 pt-16">
        <div>
          <div className="card-shadow rounded-[10px] h-full p-8">
            <div className="flex justify-between items-center pb-4">
              <p>[Ticket#378892]</p>
              <div className="flex items-center gap-2 text-red-600">
                <AiOutlineCloseCircle className="inline" />
                <p>Close Ticket</p>
              </div>
            </div>
            <div>
              <textarea
                className="w-full border-solid border-[1px] border-[#ABB3B7] rounded-3xl placeholder:text-center"
                rows={5}
              />
              <div className="flex items-center justify-end gap-2 text-sm pe-6">
                <HiReply className="inline" />
                <p>Reply</p>
              </div>
            </div>
            <div>
              <p>
                Attachments{" "}
                <span className="text-red-600">
                  Max 5 files can be uploaded. Maximum upload size is 256M
                </span>
              </p>
              <div className="grid sm:grid-cols-2 py-4">
                <div className="relative">
                  <input type="file" className="opacity-0 absolute py-2" />
                  <input
                    type="text"
                    name="email"
                    placeholder="Select file (jpg, jpeg, png, pdf, poc, docx)"
                    className="w-full h-full border-dotted border-[1px] border-[#ABB3B7] rounded-3xl placeholder:ps-4 placeholder:text-sm py-2"
                  />
                  <button className="bg-cs-blue absolute right-0 text-white px-4 py-2 rounded-3xl">
                    Upload
                  </button>
                </div>
              </div>
              <div className="flex items-center gap-2 text-cs-blue">
                <BsPlusLg className="inline" />
                <p>Add more files</p>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4 pt-4">
              <div className="h-[200px] flex flex-col items-center p-4 border-solid border-[1px] border-[#ABB3B7] rounded-[10px] gap-2">
                <FiFilePlus className="w-[50px] h-[50px]" />
                <p>Test Test</p>
                <p className="text-xs text-[#B7B4B4]">Posted Wednesday ,10th May 2023 @11:33</p>
                <button className="text-sm mt-2 flex items-center gap-2 px-2 py-1 bg-red-600 text-white rounded"><FiTrash2 className="inline" /> Delete</button>
              </div>
              <div className="h-[200px] flex flex-col items-center p-4 border-solid border-[1px] border-[#ABB3B7] rounded-[10px] gap-2">
                <FiFilePlus className="w-[50px] h-[50px]" />
                <p>Test Test</p>
                <p className="text-xs text-[#B7B4B4]">Posted Wednesday ,10th May 2023 @11:33</p>
                <button className="text-sm mt-2 flex items-center gap-2 px-2 py-1 bg-red-600 text-white rounded"><FiTrash2 className="inline" /> Delete</button>
              </div>
              <div className="h-[200px] flex flex-col items-center p-4 border-solid border-[1px] border-[#ABB3B7] rounded-[10px] gap-2">
                <FiFilePlus className="w-[50px] h-[50px]" />
                <p>Test Test</p>
                <p className="text-xs text-[#B7B4B4]">Posted Wednesday ,10th May 2023 @11:33</p>
                <button className="text-sm mt-2 flex items-center gap-2 px-2 py-1 bg-red-600 text-white rounded"><FiTrash2 className="inline" /> Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
