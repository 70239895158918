import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppDispatch } from "../../app/store";
import { setAppUser } from "../../app/features/auth/AppUser";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  interface formData {
    email: string;
    password: string;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ default: string; errorString: string }>({
    default: "An error occurred, try again...",
    errorString: "",
  });
  const [success, setSuccess] = useState<boolean>();

  const [data, setData] = useState<formData>({
    email: "",
    password: "",
  });

  useEffect(() => {
    setError({ ...error, errorString: "" });
  }, [data]);

  const login = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    try {
      axios
        .post("/admin/login", data)
        .then((res) => {
          setSuccess(true);
          setLoading(false);
          dispatch(setAppUser(res.data.data.admin));
          console.log(res)
          localStorage.setItem("auth_token", res.data.data.token);
          navigate("/dashboard");
        })
        .catch((err) => {
          setLoading(false);
          const theError = err.response?.data?.message
            ? err.response?.data?.message
            : error.default;
          setError({ ...error, errorString: theError });
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div>
      <div className="w-[100vw] min-h-[100vh] flex items-center justify-center">
        <div className="shadow-2xl max-w-[600px] w-[85vw] h-[70vh] sm:w-[80vw] sm:h-[80vh] md:w-[60vw] md:h-[70vh]">
          <div className="w-full h-full flex flex-col items-center justify-center p-6 gap-8">
            <div className="w-[80%] sm:w-[70%] md:w-[60%] pt-6">
              <div>
                <h1 className="text-cs-blue text-xl font-bold text-center pt-4 italic">
                  PAYFOCUSS ADMIN
                </h1>
              </div>
              <p className="font-bold text-xl text-center py-6">Sign In</p>
              {error.errorString ? (
                <div className="w-full bg-red-500 relative py-2 px-2 rounded">
                  <p className="text-white">{error.errorString}</p>
                </div>
              ) : null}
              {success ? (
                <div className="w-full bg-green-500 relative py-2 px-2 rounded">
                  <p className="text-white">Success... redirecting...</p>
                </div>
              ) : null}
              <form className="">
                <div className="flex flex-col gap-2 my-2">
                  <label htmlFor="email" className="text-sm">
                    Email
                  </label>
                  <input
                    required
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                    type="text"
                    name="email"
                    className="h-10 border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded px-4 w-full bg-gray-50 text-xs"
                  />
                </div>
                <div className="flex flex-col gap-2 my-2">
                  <label htmlFor="password" className="text-sm">
                    Password
                  </label>
                  <input
                    required
                    value={data.password}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                    type="password"
                    name="password"
                    className="h-10 border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded px-4 w-full bg-gray-50 text-xs"
                  />
                  <div className="flex items-center gap-2">
                    <input type="checkbox" />
                    <label htmlFor="">remember me</label>
                  </div>
                </div>
                <div className="w-full flex justify-center my-16">
                  <button
                    disabled={loading}
                    onClick={(e) => login(e)}
                    type="submit"
                    className={`bg-cs-blue px-5 py-2 rounded text-white text-center ${
                      loading ? "bg-[#6a7e90]" : ""
                    }`}
                  >
                    {loading ? "Loading..." : "Sign in"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
