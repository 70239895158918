import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { BiSearch, BiRightArrow, BiLeftArrow, BiExit } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import ItemLoader from "../../components/tableitemloader/ItemLoader";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { Drawer } from "@material-tailwind/react";

const Transactions = () => {
  interface Transaction {
    amount: string;
    charge_fixed: string;
    charge_percentage: string;
    country: string;
    created_at: string;
    currency: string;
    id: number;
    merchant_name: string;
    order_no: string;
    status: string;
    total: string;
    updated_at: string;
    user: null;
  }
  interface TransactionDetail {
    id: number;
    merchant_name: string;
    currency: string;
    country: string;
    order_no: string;
    charge_percentage: string;
    charge_fixed: string;
    amount: string;
    total: string;
    status: string;
    payer: {
      address: {
        city: string;
        state: string;
        country: string;
        street: string;
        zip_code: string;
      };
      document: string;
      email: string;
      name: string;
      phone: string;
    };
    user: null;
    created_at: string;
    updated_at: string;
  }

  const [dataLoading, setDataLoading] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);
  const [detailsDrawer, setDetailsDrawer] = useState(false)
  const [detailOrderNo, setDetailOrderNo] = useState("")
  const [detailLoading, setDetailLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [transactionDetail, setTransactionDetail] = useState<TransactionDetail>({} as TransactionDetail);
  const [links, setLinks] = useState({
    prev: null,
    next: null,
  });
  const [searchText, setSearchText] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const getNextPage = (next: string | null) => {
    if (next) {
      setNextLoading(true);
      axios
        .get(next)
        .then((res) => {
          setNextLoading(false);

          setCurrentPage(res.data.meta.current_page);
          setTransactions((prev) => (prev = res.data.data));
          setLinks(
            (prev) =>
              (prev = { prev: res.data.links.prev, next: res.data.links.next })
          );
        })
        .catch((err) => {
          setNextLoading(false);
          console.log(err);
        });
    }
  };
  
  const getPrevPage = (prev: string | null) => {
    if (prev) {
      setNextLoading(true);
      axios
        .get(prev)
        .then((res) => {
          setNextLoading(false);
          setCurrentPage(res.data.meta.current_page);
          setTransactions((state) => (state = res.data.data));
          setLinks(
            (state) =>
              (state = { prev: res.data.links.prev, next: res.data.links.next })
          );
        })
        .catch((err) => {
          setNextLoading(false);
          console.log(err);
        });
    }
  };

  const getTransactions = () => {
    axios
      .get("/admin/transaction")
      .then((res) => {
        setLinks(
          (prev) =>
            (prev = { prev: res.data.links.prev, next: res.data.links.next })
        );
        setCurrentPage(res.data.meta.current_page);
        setTransactions((prev) => (prev = res.data.data));
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const runSearch = (text: string) => {
    if (text) {
      setSearchLoading(true);
      setDataLoading(true);
      setNextLoading(true);
      axios
        .get(`/admin/transaction?search=${text}`)
        .then((res) => {
          setLinks(
            (prev) =>
              (prev = { prev: res.data.links.prev, next: res.data.links.next })
          );
          setCurrentPage(res.data.meta.current_page);
          setTransactions((prev) => (prev = res.data.data));
          setSearchLoading(false);
          setDataLoading(false);
          setNextLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSearchLoading(false);
          setDataLoading(false);
          setNextLoading(false);
        });
    }
  };

  const openDrawer = (order: string) => {
    setDetailsDrawer(true)
    setDetailOrderNo(order)
    setDetailLoading(true)
    axios.get(`/admin/transaction/${order}`).then(res => {
      console.log(res.data.data)
      setTransactionDetail(prev => prev = res.data.data)
      setDetailLoading(false);
    }).catch(err => {
      console.log(err)
    })
  }

  const closeDrawer = () => {
    setDetailsDrawer(false);
    setDetailOrderNo("");
    setTransactionDetail(prev => prev = {} as TransactionDetail)
  };

  useEffect(() => {
    if (searchText === "") {
      getTransactions();
    }
  }, [searchText]);

  return (
    <div className="">
      <div>
        <Header title="Transactions" />
      </div>
      <div className="px-6 sm:px-11 pb-11 relative">
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center ">
            <p className="py-4 font-bold text-xl">Details</p>
            <div className="relative inline-block">
              <input
                className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 py-1 pe-6 placeholder:text-center"
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="search"
              />
              <div className="absolute right-0 top-0 h-full w-[10%] flex justify-center items-center">
                <BiSearch
                  className="hover:cursor-pointer"
                  onClick={() => runSearch(searchText)}
                />
              </div>
            </div>
          </div>
          <div className="w-full overflow-auto">
            <div className="w-full p-4overflow-auto lg:overflow-visible ">
              <table className="b-table table w-full overflow-auto border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                <thead className="bg-[#80B1F8]">
                  <tr className="">
                    <th className="p-2">Id</th>
                    <th className="p-2">Order No</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Currency</th>
                    <th className="p-2">Country</th>
                    <th className="p-2">Amount</th>
                    <th className="p-2">Charges (%)</th>
                    <th className="p-2">Total (%)</th>
                    <th className="p-2">Date</th>
                    <th className="p-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dataLoading
                    ? Array(6)
                        .fill(1)
                        .map((a, row) => (
                          <tr key={row} className="bg-[#fff] shadow-xl">
                            {Array(10)
                              .fill(1)
                              .map((a, col) => (
                                <td key={col} className="p-2">
                                  <ItemLoader />
                                </td>
                              ))}
                          </tr>
                        ))
                    : null}
                  {!dataLoading &&
                    transactions.map((transaction, idx) => {
                      return (
                        <tr key={idx} className="bg-[#fff] card-shadow">
                          <td className="p-2">
                            <div className="">{transaction.id}</div>
                          </td>
                          <td className="p-2">
                            <div className="">{transaction.order_no}</div>
                          </td>
                          <td className="p-2">{transaction.merchant_name}</td>
                          <td className="p-2">{transaction.currency}</td>
                          <td className="p-2">{transaction.country}</td>
                          <td className="p-2">{Number(transaction.amount)}</td>
                          <td className="p-2">
                            {Number(transaction.charge_percentage)}
                          </td>
                          <td className="p-2">{Number(transaction.total)}</td>
                          <td className="p-2 whitespace-pre">
                            {new Date(transaction.created_at).toLocaleString()}
                          </td>
                          <td className="p-2 underline text-cs-blue font-bold hover:cursor-pointer">
                            <div
                              className=""
                              onClick={() => openDrawer(transaction.order_no)}
                            >
                              {transaction.status}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="w-full mb-4 bg-white h-[60px] card-shadow rounded-lg flex items-center justify-center gap-6">
                <div
                  onClick={() => getPrevPage(links.prev)}
                  className={`${
                    links.prev
                      ? "hover:cursor-pointer text-gray-400"
                      : "hover:cursor-not-allowed"
                  }`}
                >
                  <BiLeftArrow
                    className={`${links.prev ? "text-black" : "text-gray-400"}`}
                  />
                </div>

                <div>
                  {nextLoading ? (
                    <MoonLoader size={20} />
                  ) : (
                    <p className="text-sm">
                      Page:{" "}
                      <span className="p-1 border-solid border-[1px] border-gray-400 rounded">
                        {currentPage}
                      </span>
                    </p>
                  )}
                </div>

                <div
                  onClick={() => getNextPage(links.next)}
                  className={`${
                    links.next
                      ? "hover:cursor-pointer text-gray-400"
                      : "hover:cursor-not-allowed"
                  }`}
                >
                  <BiRightArrow
                    className={`${links.next ? "text-black" : "text-gray-400"}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {detailsDrawer && (
          <div className="fixed h-screen min-w-[300px] lg:w-[423px] overflow-y-auto">
            <Drawer
              className="p-4 overflow-y-auto"
              open={true}
              placement="right"
            >
              <div className="flex justify-end">
                <p
                  className="underline text-cs-blue font-bold cursor-pointer"
                  onClick={closeDrawer}
                >
                  <AiOutlineClose />
                </p>
              </div>
              <div className="pt-4 flex flex-col gap-2">
                <div className="flex justify-center">
                  <h4 className="font-bold">
                    Order:{" "}
                    <span className="text-gray-500">{detailOrderNo}</span>
                  </h4>
                </div>
                {detailLoading && <MoonLoader className="mx-auto" size={40} />}
                <div className="text-sm flex flex-col gap-2">
                  <p>Id: {transactionDetail.id}</p>
                  <p>Merchant Name: {transactionDetail.merchant_name}</p>
                  <p>Order No: {transactionDetail.order_no}</p>
                  <p>Currency: {transactionDetail.currency}</p>
                  <p>Amount: {transactionDetail.amount}</p>
                  <p>Total: {transactionDetail.total}</p>
                  <p>
                    Charge percentage: {transactionDetail.charge_percentage}
                  </p>
                  <p>Status: {transactionDetail.status}</p>
                </div>
                <h4 className="font-bold">Payer information</h4>
                <div className="text-sm flex flex-col gap-2">
                  <p>Name: {transactionDetail.payer?.name}</p>
                  <p>Email: {transactionDetail.payer?.email}</p>
                  <p>Phone: {transactionDetail.payer?.phone}</p>
                  <p>Document: {transactionDetail.payer?.document}</p>
                  <p className="font-bold">Address</p>
                  <p>City: {transactionDetail.payer?.address?.city}</p>
                  <p>Country: {transactionDetail.payer?.address?.country}</p>
                  <p>State: {transactionDetail.payer?.address?.state}</p>
                  <p>Street: {transactionDetail.payer?.address?.street}</p>
                  <p>ZipCode: {transactionDetail.payer?.address?.zip_code}</p>
                </div>
              </div>
            </Drawer>
          </div>
        )}
      </div>
    </div>
  );
};

export default Transactions;
