import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useAppSelector, useAppDispatch } from '../app/store'
import { toggleSidebar } from '../app/features/sidebarSlice'
import { useNavigate } from 'react-router-dom'

const Header = (props:{title: string}) => {
  const dispatch = useAppDispatch();
  const sidebarState = useAppSelector(state => state.sidebar.open)
  const appUser = useAppSelector(state => state.appUser)
  const navigate = useNavigate()
  
  return (
    <div className='min-h-[10vh] flex items-center border-b-2'>
      <div className='flex justify-between w-[100%] px-11 items-center'>
        <div className='flex gap-2 items-center'>
          <GiHamburgerMenu className='w-3 h-3 hover:cursor-pointer' onClick={() => dispatch(toggleSidebar(sidebarState ? "close" : "open"))} />
          <p className='text-cs-blue font-extrabold text-xl'>{props.title}</p>
        </div>
        
        <div className='bg-cs-blue rounded-full w-11 h-11 flex hover:cursor-pointer' onClick={()=>navigate('/profile')}>
          <div className='w-[100%] h-[100%] text-center flex items-center justify-center text-white'>
            <p>{appUser.name[0]}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header