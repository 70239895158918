import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
// import { ImSpinner6 } from "react-icons/im";
import ItemLoader from "../../components/tableitemloader/ItemLoader";
import axios from "axios";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { MoonLoader } from "react-spinners";

const Gateways = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  interface Gateway {
    id: number;
    code: string;
    alias: string;
    imageUrl: string;
    name: string;
    status: string;
    gateway_parameters: string;
    enable_payment_mode: string;
    supported_currencies: string;
    type: number;
  }
  const navigate = useNavigate()
  const [dataLoading, setDataLoading] = useState(true);
  const [gatewayData, setGatewayData] = useState<Gateway[]>([]);
  const [statusLoading, setStatusLoading] = useState(false);

  useEffect(() => {
    getGateways();
  }, []);

  const getGateways = () => {
    axios
      .get("/admin/gateway")
      .then((res) => {
        setGatewayData(res.data.data.data);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatus = (code: string, state: string, name: string) => {
    setStatusLoading(true);
    axios
      .post(`/admin/gateway/${state === "1" ? "deactivate" : "activate"}`, {
        code: code,
      })
      .then((res) => {
        getGateways();
        setStatusLoading(false);
        toast(<p>{name} {state === "1" ? "Deactivated" : "Activated"}</p>, {
          type: toast.TYPE.INFO
        })
      })
      .catch((err) => {
        console.log(err);
        setStatusLoading(false);
      });
  };

  return (
    <div>
      <div>
        <Header title="Payment GateWay" />
      </div>
      <div className="px-11 pb-11">
        <div>
          <div className="flex justify-between items-center">
            <h4 className="py-4 font-bold text-xl">Details</h4>
            <div className="relative inline-block">
              <input
                className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 py-1 pe-6 placeholder:text-center"
                type="text"
                placeholder="search"
              />
              <div className="absolute right-0 top-0 h-full w-[10%] flex justify-center items-center">
                <BiSearch
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="overflow-auto lg:overflow-visible ">
              <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                <thead className="bg-[#80B1F8]">
                  <tr className="">
                    <th className="p-2">Gateway</th>
                    <th className="p-2">Supported Currency</th>
                    <th className="p-2">Image</th>
                    <th className="p-2">Status</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dataLoading
                    ? Array(4)
                        .fill(1)
                        .map((a, row) => (
                          <tr key={row} className="bg-[#fff] card-shadow">
                            {Array(5)
                              .fill(1)
                              .map((a, col) => (
                                <td key={col} className="p-2">
                                  <ItemLoader />
                                </td>
                              ))}
                          </tr>
                        ))
                    : null}
                  {gatewayData.map((value, idx) => {
                    return (
                      <tr key={idx} className="bg-[#fff] card-shadow">
                        <td className="p-2">{value.name}</td>
                        <td className="p-2 font-bold">
                          {
                            Object.keys(JSON.parse(value.supported_currencies))
                              .length
                          }
                        </td>
                        <td className="p-2">
                          <div className="flex justify-center items-center w-[50px] h-[50px]">
                            <img
                              className="w-full h-full"
                              src={`${value.imageUrl}`}
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="p-2">
                          <p
                            className={`px-2 py-1 rounded bg-cs-blue hover:cursor-pointer text-white ${
                              !(value.status === "1") ? "bg-red-500" : ""
                            }`}
                            onClick={() =>
                              changeStatus(value.code, value.status, value.name)
                            }
                          >
                            {value.status === "1" ? "Enabled" : "Disabled"}
                          </p>
                        </td>
                        <td className="p-2 ">
                          <div className="flex justify-center gap-2">
                            <AiOutlineEye
                              className="text-xl hover:text-cs-blue hover:cursor-pointer"
                              onClick={() =>
                                navigate(`gsettings/${value.alias}`)
                              }
                            />
                            <BiEditAlt
                              className="text-xl hover:text-red-600 hover:cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `gsettings/${value.alias}?gw=${value.name}&p=edit`
                                )
                              }
                            />
                            <RiDeleteBinLine className="text-xl" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <ReactModal
            isOpen={statusLoading}
            ariaHideApp={false}
            style={customStyles}
          >
            <div className="flex justify-center items-center">
              {/* <ImSpinner6 className="text-cs-blue w-[50px] h-[50px]" /> */}
              <MoonLoader color="#0D6EFD" />
            </div>
          </ReactModal>
        </div>
      </div>
    </div>
  );
};

export default Gateways;
