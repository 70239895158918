import React, { useState, useEffect } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { AiOutlineCheck } from "react-icons/ai";
import { IoCloudUploadSharp } from "react-icons/io5";
import Header from "../../components/Header";
import FormData from "form-data";
import axios from "axios";
import { toast } from "react-toastify";

const ProductCheckout = () => {
  interface Data {
    amount: string | number;
    name: string;
    image: File | undefined;
    currency: string;
    country: string;
  }

  const [X_API_KEY, setXAPIKEY] = useState('')
  const [btnLoading, setBtnLoading] = useState(false);
  const [copied, setCopiedState] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [data, setData] = useState<Data>({
    amount: "",
    name: "",
    image: undefined,
    currency: "USD",
    country: "USA",
  });

  let formData = new FormData();
  const submitForm = () => {
    if (!data.amount || !data.name || !data.image || !data.currency || !data.country) {
      toast("All fields are required!", {
        type: toast.TYPE.WARNING,
      });
      console.log(data)
      return;
    }
    formData.append("amount", data.amount);
    formData.append("name", data.name);
    formData.append("image", data.image);
    formData.append("country", data.country);
    formData.append("currency", data.currency);

    setPaymentLink("");
    setBtnLoading(true);
    axios.defaults.headers.common["X-API-KEY"] = X_API_KEY
    axios
      .post("gateway/product_checkout", formData)
      .then((res) => {
        console.log(res.data.data);
        setPaymentLink(res.data.data.url);
        toast("Link generated", {
          type: toast.TYPE.SUCCESS,
        });
        setBtnLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("An error occured, try again!", {
          type: toast.TYPE.ERROR,
        });
        setBtnLoading(false);
      });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(paymentLink);
    setCopiedState(true);
    setTimeout(() => {
      setCopiedState(false);
    }, 3000);
  };

  const getXApiKey = () => {
    axios.get('admin/merchants/1B8C6CC5A0').then(res=>{
      // console.log()
      setXAPIKEY(res.data.data.merchant_appInfo.client_id)
    }).catch(err=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getXApiKey();
  },[])

  return (
    <div>
      <div>
        <Header title="Product Checkout" />
      </div>
      <div className="px-11 pb-11">
        <div>
          <div>
            <h4 className="py-4 font-bold text-xl">Details</h4>
          </div>
          <div className="py-4">
            <div className="min-h-[400px]">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
                <div>
                  <label htmlFor="name" className="text-base">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    className="w-full border-solid border-[1px] border-[#ABB3B7] focus:outline-cs-blue rounded-md placeholder:text-center ps-3 p-3"
                  />
                </div>
                <div>
                  <label htmlFor="amount" className="text-base">
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    value={data.amount}
                    onChange={(e) =>
                      setData({ ...data, amount: e.target.value })
                    }
                    className="w-full border-solid border-[1px] border-[#ABB3B7] focus:outline-cs-blue rounded-md placeholder:text-center ps-3 p-3"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-6">
                <div>
                  <label htmlFor="currency" className="text-base">
                    Currency
                  </label>
                  <select
                    onChange={(e) => setData({...data, currency: e.target.value})}
                    name="currency"
                    className="w-full border-solid border-[1px] focus:outline-cs-blue border-[#ABB3B7] rounded-md text-sm ps-3 p-3"
                  >
                    <option value="NGN">NGN</option>
                    <option selected value="USD">USD</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="country" className="text-base">
                    Country
                  </label>
                  <select
                    onChange={(e) => setData({...data, country: e.target.value})}
                    name="country"
                    className="w-full border-solid border-[1px] border-[#ABB3B7] rounded-md text-sm ps-3 p-3"
                  >
                    <option value="NG">Nigeria</option>
                    <option selected value="US">United States</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="image" className="text-base">
                    Image
                  </label>
                  <div className="relative w-full">
                    <input
                      type="file"
                      name="image"
                      accept="image/jpeg,image/png,image/gif"
                      onChange={(e) =>
                        setData({ ...data, image: e.target.files?.[0] })
                      }
                      className="w-full hover:cursor-pointer border-solid border-[1px] border-[#ABB3B7] rounded-md text-sm ps-3 p-3 opacity-0"
                    />
                    <div className="absolute -z-10 w-full text-center top-0 left-0 border-solid border-[1px] border-[#ABB3B7] rounded-md text-sm p-3 ">
                      <div className="w-full inline bg-[#E5E6E8] text-sm rounded p-1">
                        {data.image?.name ? (
                          data.image.name
                        ) : (
                          <span>
                            <IoCloudUploadSharp className="inline" /> Drag to
                            upload or Browse
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex sm:justify-center pt-11">
                <div className="flex gap-4">
                  <input
                    type="text"
                    name="link"
                    readOnly
                    value={paymentLink}
                    className="w-full md:w-[350px] lg:w-[500px] border-solid border-[1px] border-[#ABB3B7] rounded-md ps-3 p-2"
                  />
                  <div
                    className="flex items-center gap-1 text-cs-blue hover:cursor-pointer"
                    onClick={copyToClipboard}
                  >
                    {copied ? <AiOutlineCheck /> : <MdOutlineContentCopy />}
                    <p className="whitespace-pre">
                      {copied ? "Copied" : "Copy link"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center pt-6">
                <button
                  disabled={btnLoading}
                  className={`bg-cs-blue text-white px-[92px] py-[20px] text-center rounded-[10px] ${
                    btnLoading ? "bg-[#adcae6]" : ""
                  }`}
                  onClick={submitForm}
                >
                  {btnLoading ? "Loading..." : "Generate Link"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCheckout;
