import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { HiOutlineUsers, HiOutlineCreditCard } from "react-icons/hi";
import { TbSmartHome } from "react-icons/tb";
import { RxDotFilled } from "react-icons/rx";
import { Pie, PieChart, Cell, ResponsiveContainer } from "recharts";
import { pieChartData, COLORS } from "./data";
import axios from "axios";
import { useAppSelector } from "../../app/store";

const Dashboard = () => {
  interface Stat {
    total_user_count: number;
    total_admin_count: number;
    total_merchant_count: number;
    total_gateway_count: number;
    total_currencies_count: number;
    total_product_page_count: number;
    total_product_price_sum: string
    total_transactions_count: number
    total_paid_transactions_count: number
    total_paid_transactions_sum: string
    total_paid_transactions_sum_with_charges: string
    total_pending_transactions_count: number
    total_refund_transactions_count: number
  }
  const [stat, setStats] = useState<Stat>({} as Stat);
  const [chartData, setChartData] = useState<Record<string, chartType[]>>({
    transactions: [],
  } as Record<string, chartType[]>);
  type chartType = {
    title: string;
    value: number;
  };
  const roleName = useAppSelector((state) => state.appUser.role.name);

  const getStat = () => {
    axios
      .get("/admin/dashboard")
      .then((res) => {
        setStats(prev => prev = res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setChartData(
      (prev) =>
        (prev = {
          ...prev,
          transactions: [
            {
              title: "Total Transactions",
              value: stat.total_transactions_count,
            },
            {
              title: "Approved Transactions",
              value: stat.total_paid_transactions_count,
            },
            {
              title: "Pending Transactions",
              value: stat.total_pending_transactions_count,
            },
          ],
        })
    );
  }, [stat]);

  useEffect(() => {
    getStat();
    // refreshData();
  }, []);
  // const refreshData = () => {
  //   setInterval(() => {
  //     getStat();
  //   }, 30000);
  // }


  return (
    <div>
      <div>
        <Header title="Dashboard" />
      </div>
      <div className="px-11 pb-11">
        <div>
          <div>
            <h4 className="py-4 font-bold text-xl">Overview</h4>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="card-shadow rounded-[10px]">
              <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                <p>Total Users</p>
                <p className="font-bold">{stat.total_user_count}</p>
                <div className="bg-cs-blue text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                  <HiOutlineUsers className="w-[25px] h-[25px]" />
                </div>
              </div>
            </div>
            <div className="card-shadow rounded-[10px]">
              <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                <p>Total Merchants</p>
                <p className="font-bold">{stat.total_merchant_count}</p>
                <div className="bg-[#2C9D04] text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                  <HiOutlineUsers className="w-[25px] h-[25px]" />
                </div>
              </div>
            </div>
            <div className="card-shadow rounded-[10px]">
              <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                <p>Total Gateway</p>
                <p className="font-bold">{stat.total_gateway_count}</p>
                <div className="bg-[#CE0303] text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                  <HiOutlineUsers className="w-[25px] h-[25px]" />
                </div>
              </div>
            </div>
            <div className="card-shadow rounded-[10px]">
              <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                <p>Total Transactions</p>
                <p className="font-bold">{stat?.total_transactions_count}</p>
                <div className="bg-[#F9B104] text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                  <HiOutlineCreditCard className="w-[25px] h-[25px]" />
                </div>
              </div>
            </div>
            <div className="card-shadow rounded-[10px]">
              <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                <p>Approved Transactions</p>
                <p className="font-bold">
                  {stat?.total_paid_transactions_count}
                </p>
                <div className="bg-[#2C9D04] text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                  <HiOutlineCreditCard className="w-[25px] h-[25px]" />
                </div>
              </div>
            </div>
            <div className="card-shadow rounded-[10px]">
              <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                <p>Pending Transactions</p>
                <p className="font-bold">
                  {stat?.total_pending_transactions_count}
                </p>
                <div className="bg-[#CE0303] text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                  <HiOutlineCreditCard className="w-[25px] h-[25px]" />
                </div>
              </div>
            </div>
            {roleName === "Super Admin" ||
              (roleName === "Developer Access" && (
                <div className="card-shadow rounded-[10px]">
                  <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                    <p>Total Transactions Sum</p>
                    <p className="font-bold">
                      {stat
                        .total_paid_transactions_sum&&Number(
                          stat.total_paid_transactions_sum
                        )
                        .toFixed(2)}
                    </p>
                    <div className="bg-[#E87001] text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                      <TbSmartHome className="w-[25px] h-[25px]" />
                    </div>
                  </div>
                </div>
              ))}
            {roleName === "Super Admin" ||
              (roleName === "Developer Access" && (
                <div className="card-shadow rounded-[10px]">
                  <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                    <p>Transactions with Charges</p>
                    <p className="font-bold">
                      {stat?.total_paid_transactions_sum_with_charges &&
                        Number(
                          stat.total_paid_transactions_sum_with_charges
                        ).toFixed(2)}
                    </p>
                    <div className="bg-[#2C9D04] text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                      <TbSmartHome className="w-[25px] h-[25px]" />
                    </div>
                  </div>
                </div>
              ))}
            {roleName === "Super Admin" ||
              (roleName === "Developer Access" && (
                <div className="card-shadow rounded-[10px]">
                  <div className="h-[120px] relative flex flex-col items-center justify-center text-sm">
                    <p>Total Admins</p>
                    <p className="font-bold">{stat.total_admin_count}</p>
                    <div className="bg-[#CE0303] text-white flex items-center justify-center absolute rounded-xl w-[60px] h-[50px] right-[-5%] top-[-15%]">
                      <TbSmartHome className="w-[25px] h-[25px]" />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 py-6">
            {/* <div className="h-320px rounded-[10px] card-shadow">
              <div className="p-2 flex flex-col items-center">
                <p className="font-bold">Users</p>
                <div className="w-[100%] h-[200px]">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        innerRadius={30}
                        outerRadius={90}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="ms-0">
                  <p>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-green-600" />
                    Standard
                  </p>
                  <div>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-red-600" />
                    Silver
                  </div>
                  <div>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-yellow-600" />
                    Gold
                  </div>
                </div>
              </div>
            </div> */}
            <div className="h-320px rounded-[10px] card-shadow">
              <div className="p-2 flex flex-col items-center">
                <p className="font-bold">Transactions</p>
                <div className="w-[100%] h-[200px]">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={chartData.transactions}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        innerRadius={30}
                        outerRadius={90}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {/* {chartData.transactions.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))} */}
                        <Cell fill="#F9B104" />
                        <Cell fill="#2C9D04" />
                        <Cell fill="#CE0303" />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="ms-0">
                  <p>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-[#F9B104]" />
                    Total Transactions
                  </p>
                  {/* <div>Standard</div> */}
                  <div>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-[#2C9D04]" />
                    Approved Transactions
                  </div>
                  <div>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-[#CE0303]" />
                    Pending Transactions
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex justify-center pb-4">
            <div className="h-[330px] w-[100%] sm:w-[50%] rounded-[10px] card-shadow">
              <div className="p-2 flex flex-col items-center">
                <p className="font-bold">Merchants</p>
                <div className="w-[100%] h-[200px]">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        innerRadius={30}
                        outerRadius={90}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="ms-0">
                  <p>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-green-600" />
                    Total Merchants
                  </p>
                  <div>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-red-600" />
                    Approved Merchants
                  </div>
                  <div>
                    <RxDotFilled className="inline w-[30px] h-[30px]  text-yellow-600" />
                    Pending Merchants
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
