import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import sidebarReducer from './features/sidebarSlice'
import appUserReducer from './features/auth/AppUser'


export const store = configureStore({
  reducer:{
    sidebar: sidebarReducer,
    appUser: appUserReducer
  }
})


export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;