import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainLayout = () => {
  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="flex-[4] overflow-auto">
        <Outlet />
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={true}
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default MainLayout;
