import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { TbCreditCard } from "react-icons/tb";
import { BiChevronRight, BiWallet } from "react-icons/bi";
import { BsTicketDetailed } from "react-icons/bs";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { toast } from "react-toastify";

const UserDetails = () => {
  interface UserDetail {
    bvn: string;
    birth_day: string;
    two_step_verification_type: string;
    address: string;
    city: string;
    state: string;
    country: string;
    country_code: string;
    currency_code: string;
    currency: string;
    currency_symbol: string;
    timezone: string;
    address_1: null;
    address_2: null;
    last_login_ip: string;
    last_login_at: null;
  }
  interface User {
    id: number;
    username: string;
    email: string;
    phone: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    status: string;
    user_detail: UserDetail;
    role: {
      name: string;
      type: string;
      customer_type: string;
    };
  }

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { id } = useParams();
  const editPage = query.get("p") === "edit";
  const [dataLoading, setDataLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [userData, setUserData] = useState<User>({} as User);
  const [modData, setModData] = useState<User>({} as User);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setDataLoading(true);
    axios
      .get(`/admin/user/${id}`)
      .then((res) => {
        setDataLoading(false);
        setUserData((prev) => (prev = res.data.data));
        setModData((prev) => (prev = res.data.data));
      })
      .catch((err) => {
        setDataLoading(false);
        console.log(err);
      });
  };

  const updateUser = () => {
    console.log(id);
    setUpdateLoading(true);
    const data = {
      type: "user",
      email: userData.email,
      username: userData.username,
      first_name: userData.first_name,
      middle_name: userData.middle_name,
      last_name: userData.last_name,
      bvn: userData.user_detail.bvn,
      birth_day: userData.user_detail.birth_day,
      phone: userData.phone,
      country_code: "ng",
      // password: "password",
      status: userData.status,
      _method: "PUT",
    };
    try {
      axios
        .put(`/admin/user/${id}`, data)
        .then((res) => {
          setUpdateLoading(false);
          toast(res.data.message, {
            type: toast.TYPE.SUCCESS,
          });
        })
        .catch((err) => {
          console.log(err);
          setUpdateLoading(false);
          toast(err.response?.data?.message, {
            type: toast.TYPE.INFO,
          });
          if (!err.response?.data?.message) {
            toast(err.message, {
              type: toast.TYPE.INFO,
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    // setModData({ [e.target.name]: e.target.value });
    if (Object.keys(userData.user_detail).includes(e.target.name)) {
      console.log(e.target.name);
      setUserData(
        (prev) =>
          (prev = {
            ...prev,
            user_detail: {
              ...prev.user_detail,
              [e.target.name]: e.target.value,
            },
          })
      );
    } else {
      setUserData(
        (prev) =>
          (prev = {
            ...(userData as User),
            user_detail: { ...prev.user_detail },
            [e.target.name]: e.target.value,
          })
      );
    }
    console.log(userData);
  };

  return (
    <div>
      <div>
        <Header title="User Details" />
      </div>
      <div className="px-11 pb-11">
        {dataLoading ? (
          <div className="w-full pt-6 flex items-center justify-center">
            <MoonLoader color="#0D6EFD" size={30} />
          </div>
        ) : null}
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 py-6 gap-6">
            <div className="card-shadow h-[120px] rounded-[10px] flex items-center gap-4 ps-4 relative">
              <div className="bg-cs-blue text-white w-[50px] h-[50px] rounded-full flex items-center justify-center">
                <TbCreditCard className="h-[60%] w-[60%]" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="font-bold text-lg">₦0</p>
                <p className="text-sm text-[#B7B4B4]">Balance</p>
              </div>
              <p className="absolute text-cs-blue text-xs bottom-[5%] right-[5%] cursor-pointer">
                View all <BiChevronRight className="inline" />
              </p>
            </div>
            <div className="card-shadow h-[120px] rounded-[10px] flex items-center gap-4 ps-4 relative">
              <div className="bg-[#2C9D04] text-white w-[50px] h-[50px] rounded-full flex items-center justify-center">
                <BiWallet className="h-[60%] w-[60%]" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="font-bold text-lg">₦0</p>
                <p className="text-sm text-[#B7B4B4]">Withdrawn</p>
              </div>
              <p className="absolute text-cs-blue text-xs bottom-[5%] right-[5%] cursor-pointer">
                View all <BiChevronRight className="inline" />
              </p>
            </div>
            <div className="card-shadow h-[120px] rounded-[10px] flex items-center gap-4 ps-4 relative">
              <div className="bg-[#E87001] text-white w-[50px] h-[50px] rounded-full flex items-center justify-center">
                <BsTicketDetailed className="h-[60%] w-[60%] text-white" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="font-bold text-lg">0</p>
                <p className="text-sm text-[#B7B4B4]">Support Ticket</p>
              </div>
              <p className="absolute text-cs-blue text-xs bottom-[5%] right-[5%] cursor-pointer">
                View all <BiChevronRight className="inline" />
              </p>
            </div>
          </div>
          <div className="card-shadow rounded-[10px] h-[100%] p-6">
            <div>
              <p className="font-bold">Information of {query.get("user")}</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-6">
              <div>
                <label htmlFor="username" className="text-sm">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  value={userData?.username}
                  onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] outline-none focus:border-[#0D6EFD] border-[#ABB3B7] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="first_name" className="text-sm">
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={userData?.first_name}
                  onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] outline-none focus:border-[#0D6EFD] border-[#ABB3B7] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="middle_name" className="text-sm">
                  Middle Name
                </label>
                <input
                  type="text"
                  name="middle_name"
                  value={userData?.middle_name}
                  onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] outline-none focus:border-[#0D6EFD] border-[#ABB3B7] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="last_name" className="text-sm">
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  value={userData?.last_name}
                  onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-6">
              <div>
                <label htmlFor="email" className="text-sm">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={userData?.email}
                  onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="phone" className="text-sm">
                  Mobile Number
                </label>
                <input
                  type="text"
                  name="phone"
                  value={userData?.phone}
                  onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="bvn" className="text-sm">
                  BVN
                </label>
                <input
                  type="text"
                  name="bvn"
                  value={userData?.user_detail?.bvn}
                  onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="birth_day" className="text-sm">
                  Birthday
                </label>
                <input
                  type="text"
                  name="birth_day"
                  value={userData?.user_detail?.birth_day}
                  onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="status" className="text-sm">
                  Status
                </label>
                {/* <input
                  type="text"
                  name="status"
                  value={userData?.status}
                  // onChange={(e) => handleFormChange(e)}
                  readOnly={!editPage}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                /> */}
                <select
                  name="status"
                  defaultValue={userData?.status}
                  id=""
                  onChange={(e) => handleFormChange(e)}
                  value={userData?.status}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div className="pt-6">
              <label htmlFor="address" className="text-sm">
                Address
              </label>
              <input
                type="text"
                name="address"
                value={userData?.user_detail?.address}
                readOnly={!editPage}
                onChange={(e) => handleFormChange(e)}
                className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 pt-6">
              <div>
                <label htmlFor="city" className="text-sm">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  value={userData?.user_detail?.city}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="state" className="text-sm">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={userData?.user_detail?.state}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="country" className="text-sm">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  value={userData?.user_detail?.country}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="currency" className="text-sm">
                  Currency
                </label>
                <input
                  type="text"
                  name="currency"
                  value={userData?.user_detail?.currency}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 pt-6">
              <div className="border-[1px] border-solid border-[#B7B4B4] rounded-[10px] h-[80px] flex flex-col items-center">
                <p className="pt-1">Two step Verification</p>
                <p
                  className={`${
                    userData?.user_detail?.two_step_verification_type ===
                    "enabled"
                      ? "text-[#2C9D04]"
                      : "text-[#CE0303]"
                  } pt-2 font-bold text-sm`}
                >
                  {userData?.user_detail?.two_step_verification_type}
                </p>
              </div>
              {/* <div className="border-[1px] border-solid border-[#B7B4B4] rounded-[10px] h-[80px] flex flex-col items-center">
                <p className="pt-1">Mobile Verification</p>
                <p className="pt-2 text-[#2C9D04] font-bold text-sm">
                  Verified
                </p>
              </div>
              <div className="border-[1px] border-solid border-[#B7B4B4] rounded-[10px] h-[80px] flex flex-col items-center">
                <p className="pt-1">KYC</p>
                <p className="pt-2 text-[#CE0303] font-bold text-sm">
                  Disabled
                </p>
              </div> */}
            </div>
            {editPage ? (
              <div className="flex justify-center items-center pt-6">
                <button
                  onClick={() => updateUser()}
                  className={`py-1 px-6 bg-cs-blue text-white text-sm rounded ${
                    updateLoading ? "opacity-60" : null
                  }`}
                  disabled={updateLoading}
                >
                  {updateLoading ? (
                    <div className="w-full h-full flex justify-center">
                      <MoonLoader color="#fff" size={15} />
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
