import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { BiSearch, BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import ItemLoader from "../../components/tableitemloader/ItemLoader";

const Merchants = () => {
  const navigate = useNavigate()
  interface Merchant {
    id: number;
    merchant_uuid: string;
    merchant_business_name: string;
    merchant_site_url: string;
    about_merchant: null;
    logo: string;
    merchant_address: {
      name: string;
      email: string;
      document: string;
      phone: string;
      address: {
        country: string;
        state: string;
        city: string;
        zip_code: string;
        street: string;
        number: string;
      };
    };
    merchant_fee: {
      fee_1: {
        name: "Tax Fee";
        value: 2;
        type: "percentage";
      };
      fee_2: {
        name: "Constant Fee";
        value: 0;
        type: "fixed";
      };
    };
    merchant_status: string;
    merchant_appInfo: {
      id: number;
      client_id: string;
      client_secret: string;
      client_callback_url: string;
      client_webhook_url: string;
    };
  }

  const [dataLoading, setDataLoading] = useState(true);
  const [merchants, setMerchants] = useState<Merchant[]>([]);

  useEffect(() => {
    axios
      .get("/admin/merchants")
      .then((res) => {
        setMerchants(res.data.data);
        setDataLoading(false);
        console.log(merchants);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div>
        <Header title="Merchants" />
      </div>
      <div className="px-11 pb-11">
        <div>
          <div className="flex justify-between items-center">
            <h4 className="py-4 font-bold text-xl">Details</h4>
            <div className="relative inline-block">
              <input
                className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 py-1 pe-6 placeholder:text-center"
                type="text"
                placeholder="search"
              />
              <div className="absolute right-0 top-0 h-full w-[10%] flex justify-center items-center">
                <BiSearch
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
          </div>

          <div className="w-[100%] overflow-auto">
            <div className="overflow-auto lg:overflow-visible ">
              <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                <thead className="bg-[#80B1F8]">
                  <tr className="">
                    <th className="p-2">Id</th>
                    <th className="p-2">Business Name</th>
                    <th className="p-2">Email</th>
                    <th className="p-2">Phone</th>
                    <th className="p-2">Country</th>
                    <th className="p-2">Site_Url</th>
                    <th className="p-2">Transactions</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dataLoading
                    ? Array(6)
                        .fill(1)
                        .map((a, row) => (
                          <tr key={row} className="bg-[#fff] card-shadow">
                            {Array(8)
                              .fill(1)
                              .map((a, col) => (
                                <td key={col} className="p-2">
                                  <ItemLoader />
                                </td>
                              ))}
                          </tr>
                        ))
                    : null}
                  {merchants.map((merchant, idx) => {
                    return (
                      <tr key={idx} className="bg-[#fff] card-shadow">
                        <td className="p-2">{merchant.merchant_uuid}</td>

                        <td className="p-2">
                          {merchant.merchant_business_name}
                        </td>
                        <td className="p-2">
                          {merchant.merchant_address.email}
                        </td>
                        <td className="p-2">
                          {merchant.merchant_address.phone}
                        </td>
                        <td className="p-2 font-bold">
                          {merchant.merchant_address.address.country}
                        </td>
                        <td className="p-2">{merchant.merchant_site_url}</td>
                        <td className="p-2 text-cs-blue">
                          <p
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(`transaction/${merchant.id}`)
                            }
                          >
                            view
                          </p>
                        </td>
                        <td className="p-2 ">
                          <div className="flex justify-center gap-2">
                            <AiOutlineEye
                              className="text-xl hover:text-cs-blue hover:cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `details/${merchant.merchant_uuid}?mht=${merchant.merchant_business_name}`
                                )
                              }
                            />
                            <BiEditAlt
                              className="text-xl hover:text-red-600 hover:cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `details/${merchant.merchant_uuid}?mht=${merchant.merchant_business_name}&p=edit`
                                )
                              }
                            />
                            <RiDeleteBinLine className="text-xl" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchants;
