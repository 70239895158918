import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { BiEditAlt, BiSearch } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye, AiOutlineCloseCircle } from "react-icons/ai";
import ItemLoader from "../../components/tableitemloader/ItemLoader";
import axios from "axios";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { MoonLoader } from "react-spinners";

const Users = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const deleteStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  interface User {
    id: number;
    username: string;
    email: string;
    phone: string;
    first_name: string;
    middle_name: string | null;
    last_name: string;
    status: string;
    role: {
      name: string;
      type: string;
      customer_type: string;
    };
    user_detail: {
      bvn: string;
      birth_day: string;
      two_step_verification_type: string;
      address: null;
      city: null;
      state: null;
      country: string;
      country_code: string;
      currency_code: string;
      currency: string;
      currency_symbol: string;
      timezone: string;
      address_1: null;
      address_2: null;
      last_login_ip: string;
      last_login_at: null;
    };
  }

  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [statusLoading, setStatusLoading] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);

  const getUsers = () => {
    axios
      .get("/admin/user")
      .then((res) => {
        setUsers((prev) => (prev = res.data.data));
        setUsers((prev) => (prev = prev.filter((e) => e.role.type === "User")));
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteUser = (id: string | number) => {
    setStatusLoading(true);
    setDeleteWarning(false);
    axios
      .delete(`/admin/user/${id}`)
      .then((res) => {
        setStatusLoading(false);
        toast(res.data.message, {
          type: toast.TYPE.SUCCESS,
        });
        getUsers();
      })
      .catch((err) => {
        console.log(err);
        setStatusLoading(false);
        toast(err.response?.data?.message, {
          type: toast.TYPE.INFO,
        });
        if (!err.response?.data?.message) {
          toast(err.message, {
            type: toast.TYPE.INFO,
          });
        }
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <div>
        <Header title="Users" />
      </div>
      <div className="px-11 pb-11">
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center ">
            <p className="py-4 font-bold text-xl">Details</p>
            <div className="relative inline-block">
              <input
                className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl ps-3 py-1 pe-6 placeholder:text-center"
                type="text"
                placeholder="search"
              />
              <div className="absolute right-0 top-0 h-full w-[10%] flex justify-center items-center">
                <BiSearch className="hover:cursor-pointer" />
              </div>
            </div>
          </div>
          <div>
            <div className="overflow-auto lg:overflow-visible ">
              <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                <thead className="bg-[#80B1F8]">
                  <tr className="">
                    <th className="p-2">S/N</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Phone</th>
                    <th className="p-2">Email</th>
                    <th className="p-2">Country</th>
                    {/* <th className="p-2">Joined At</th> */}
                    {/* <th className="p-2">Balance</th> */}
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dataLoading
                    ? Array(6)
                        .fill(1)
                        .map((a, row) => (
                          <tr key={row} className="bg-[#fff] card-shadow">
                            {Array(6)
                              .fill(1)
                              .map((a, col) => (
                                <td key={col} className="p-2">
                                  <ItemLoader />
                                </td>
                              ))}
                          </tr>
                        ))
                    : null}

                  {users.map((value, idx) => {
                    return (
                      <tr key={idx} className="bg-[#fff] card-shadow">
                        <td className="p-2">
                          <div className="">{value.id}</div>
                        </td>
                        <td className="p-2">{value.username}</td>
                        <td className="p-2">{value.phone}</td>
                        <td className="p-2">{value.email}</td>
                        <td className="p-2">Nigeria</td>
                        <td className="p-2 ">
                          <div className="flex justify-center gap-2">
                            <AiOutlineEye
                              onClick={() =>
                                navigate(
                                  `details/${value.id}?user=${value.username}`
                                )
                              }
                              className="text-xl hover:text-cs-blue"
                            />
                            <BiEditAlt
                              onClick={() =>
                                navigate(
                                  `details/${value.id}?user=${value.username}&p=edit`
                                )
                              }
                              className="text-xl hover:text-[#E87001]"
                            />
                            <RiDeleteBinLine
                              onClick={() => setDeleteWarning(true)}
                              className="text-xl hover:text-[#C70202]"
                            />
                          </div>
                        </td>
                        <ReactModal
                          isOpen={deleteWarning}
                          ariaHideApp={true}
                          style={deleteStyles}
                        >
                          <div className="min-w-[200px] flex flex-col items-center p-6">
                            <div className="text-red-400 flex items-center gap-2">
                              <AiOutlineCloseCircle />
                              <p className="font-bold whitespace-pre ">
                                Delete User?
                              </p>
                            </div>
                            <div className="flex gap-2 pt-6">
                              <button
                                onClick={() => deleteUser(value.id)}
                                className="bg-red-500 text-white px-4 py-1 rounded-lg"
                              >
                                Yes
                              </button>
                              <button
                                onClick={() => setDeleteWarning(false)}
                                className="border-solid border-[1px] border-cs-blue text-gray-400 px-4 py-1 rounded-lg"
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </ReactModal>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={statusLoading}
          ariaHideApp={false}
          style={customStyles}
        >
          <div className="flex justify-center items-center">
            <MoonLoader color="#0D6EFD" />
          </div>
        </ReactModal>
      </div>
    </div>
  );
};

export default Users;
