import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import { CiGrid42 } from "react-icons/ci";
import { HiOutlineUsers } from "react-icons/hi";
import { BsCoin } from "react-icons/bs";
import { FiPower } from "react-icons/fi";
import { PiCardholderLight } from "react-icons/pi";
import { HiOutlineStopCircle } from "react-icons/hi2";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineClose, AiOutlinePicRight } from "react-icons/ai";
import { BsTicketDetailed, BsPersonCheckFill } from "react-icons/bs";
import { TbCreditCard, TbSmartHome } from "react-icons/tb";
import { selectSidebarState } from "../app/features/sidebarSlice";
import { toggleSidebar } from "../app/features/sidebarSlice";
import { useAppSelector, useAppDispatch } from "../app/store";
import { IconType } from "react-icons/lib";

interface menuItems {
  icon: IconType;
  name: string;
  link: string;
}

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const [subMenuState, setSubMenuState] = useState({
    // merchant: {
    //   open: false,
    //   menuItems: [
    //     {
    //       icon: HiOutlineStopCircle,
    //       name: "Client Secret",
    //       link: "client-secret",
    //     },
    //   ],
    // },
    payin: {
      open: false,
      menuItems: [
        {
          icon: HiOutlineStopCircle,
          name: "Payment Link",
          link: "payment-link",
        },
        {
          icon: HiOutlineStopCircle,
          name: "Uniccon VR Checkout",
          link: "product-checkout",
        },
      ],
    },
    privileges: {
      open: false,
      menuItems: [
        {
          icon: HiOutlineStopCircle,
          name: "Roles and Permission",
          link: "roles",
        },
        {
          icon: HiOutlineStopCircle,
          name: "Employees",
          link: "employees",
        },
      ],
    },
  });

  const concile = (subMenu: menuItems[]) => {
    var include = false;
    subMenu.map((item) => {
      if (url.includes(item.link)) {
        include = true;
      }
      return null;
    });
    return include;
  };

  const dispatch = useAppDispatch();
  const sidebarState = useAppSelector(selectSidebarState);
  const usePermissions = useAppSelector(
    (state) => state.appUser.role.module_access
  );

  const [width, setWidth] = React.useState(window.innerWidth);
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  useEffect(() => {
    if (width < 786) {
      dispatch(toggleSidebar("close"));
    } else {
      dispatch(toggleSidebar("open"));
    }
  }, [width, dispatch]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div
      className={`bg-cs-blue overflow-auto flex-1 max-w-[323px] ${
        width < 786 ? "absolute z-50 h-[100vh]" : "relative"
      }`}
      style={{ display: `${sidebarState ? "" : "none"}` }}
    >
      <div className="min-h-[10vh]">
        <div className="flex ps-8 pe-2 items-center">
          <img className="pt-11" src="/images/logo.png" alt="" />
          {width < 786 ? (
            <AiOutlineClose
              className="text-black w-3 h-3"
              onClick={() => dispatch(toggleSidebar("close"))}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <ul className="py-8 list-none text-white text-base">
          {usePermissions.dashboard_management && (
            <li
              className={
                (url.includes("dashboard")
                  ? "bg-[#80B1F8] rounded-r-3xl"
                  : "") + " max-w-[100%] pe-2"
              }
            >
              <Link
                to="/dashboard"
                className="flex items-center gap-2 ps-4 py-3"
              >
                <CiGrid42 className="text-lg" />
                Dashboard
              </Link>
            </li>
          )}
          {usePermissions.user_management && (
            <li
              className={
                (url.includes("users") ? "bg-[#80B1F8] rounded-r-3xl" : "") +
                " max-w-[100%] pe-2"
              }
            >
              <Link to="/users" className="flex items-center gap-2 ps-4 py-3">
                <HiOutlineUsers className="text-lg" />
                Users
              </Link>
            </li>
          )}
          {usePermissions.financial_management && (
            <li
              className={
                (url.includes("payment-gateways")
                  ? "bg-[#80B1F8] rounded-r-3xl"
                  : "") + " max-w-[100%] pe-2"
              }
            >
              <Link
                to="/payment-gateways"
                className="flex items-center gap-2 ps-4 py-3"
              >
                <TbCreditCard className="text-lg" />
                Payment Gateway
              </Link>
            </li>
          )}
          {usePermissions.merchant_management && <li
            className={
              (url.includes("merchants") 
                ? "bg-[#80B1F8] rounded-r-3xl"
                : "") + " max-w-[100%] pe-2"
            }
          >
            <Link to="/merchants" className="flex items-center gap-2 ps-4 py-3">
              <TbSmartHome className="text-lg" />
              Merchants
            </Link>
            {/* <div
              className="w-full flex justify-center cursor-pointer"
              onClick={() =>
                setSubMenuState(
                  (prev) =>
                    (prev = {
                      ...prev,
                      merchant: {
                        ...subMenuState.merchant,
                        open: !subMenuState.merchant.open,
                      },
                    })
                )
              }
            >
              {subMenuState.merchant.open ? (
                <IoIosArrowUp className="font-bold" />
              ) : (
                <IoIosArrowDown className="font-bold" />
              )}
            </div> */}
          </li>}
          {/* {usePermissions.merchant_management && subMenuState.merchant.open &&
            subMenuState.merchant.menuItems.map((item) => {
              return (
                <li
                  className={
                    (url.includes(item.link)
                      ? "bg-[#80B1F8] rounded-r-3xl"
                      : "") + ""
                  }
                >
                  <Link
                    to={"merchants/" + item.link}
                    className="text-sm flex items-center gap-1 ps-10 py-2"
                  >
                    <item.icon className="inline" />
                    {item.name}
                  </Link>
                </li>
              );
            })} */}
          {usePermissions.support_management && <li
            className={
              (url.includes("support-tickets")
                ? "bg-[#80B1F8] rounded-r-3xl"
                : "") + " max-w-[100%] pe-2"
            }
          >
            <Link
              to="/support-tickets"
              className="flex items-center gap-2 ps-4 py-3"
            >
              <BsTicketDetailed className="text-lg" />
              Support Tickets
            </Link>
          </li>}
          {usePermissions.financial_management && <li
            className={
              (url.includes("transactions")
                ? "bg-[#80B1F8] rounded-r-3xl"
                : "") + " max-w-[100%] pe-2"
            }
          >
            <Link
              to="/transactions"
              className="flex items-center gap-2 ps-4 py-3"
            >
              <PiCardholderLight className="text-lg" />
              Transactions
            </Link>
          </li>}
          {usePermissions.financial_management && <li
            className={
              (url.includes("payout") ? "bg-[#80B1F8] rounded-r-3xl" : "") +
              " max-w-[100%] pe-2"
            }
          >
            <Link to="/payout" className="flex items-center gap-2 ps-4 py-3">
              <BsCoin className="text-lg" />
              Payout
            </Link>
          </li>}

          {usePermissions.financial_management && <li
            className={" max-w-[100%] pe-2 flex items-center justify-between"}
          >
            <div className="flex items-center gap-2 ps-4 py-3">
              <AiOutlinePicRight className="text-lg" />
              Payin
            </div>
            <div
              className="w-full flex justify-center cursor-pointer"
              onClick={() =>
                setSubMenuState(
                  (prev) =>
                    (prev = {
                      ...prev,
                      payin: {
                        ...subMenuState.payin,
                        open: !subMenuState.payin.open,
                      },
                    })
                )
              }
            >
              {subMenuState.payin.open ? (
                <IoIosArrowUp className="font-bold" />
              ) : (
                <IoIosArrowDown className="font-bold" />
              )}
            </div>
          </li>}
          {usePermissions.financial_management && subMenuState.payin.open &&
            subMenuState.payin.menuItems.map((item) => {
              return (
                <li
                  className={
                    (url.includes(item.link)
                      ? "bg-[#80B1F8] rounded-r-3xl"
                      : "") + ""
                  }
                >
                  <Link
                    to={item.link}
                    className="text-sm flex items-center gap-1 ps-10 py-2"
                  >
                    <item.icon className="inline" />
                    {item.name}
                  </Link>
                </li>
              );
            })}
          {/* <li
            className={
              (url.includes("roles") ? "bg-[#80B1F8] rounded-r-3xl" : "") +
              " max-w-[100%] pe-2"
            }
          >
            <Link to="/roles" className="flex items-center gap-2 ps-4 py-3">
              <BsPersonCheckFill className="text-lg" />
              Role & Permission
            </Link>
          </li> */}
          {usePermissions.privilege_management && <li
            className={" max-w-[100%] pe-2 flex items-center justify-between"}
          >
            <div className="flex items-center gap-2 ps-4 py-3">
              <BsPersonCheckFill className="text-lg" />
              Privileges
            </div>
            <div
              className="w-full flex justify-center cursor-pointer"
              onClick={() =>
                setSubMenuState(
                  (prev) =>
                    (prev = {
                      ...prev,
                      privileges: {
                        ...subMenuState.privileges,
                        open: !subMenuState.privileges.open,
                      },
                    })
                )
              }
            >
              {subMenuState.privileges.open ? (
                <IoIosArrowUp className="font-bold" />
              ) : (
                <IoIosArrowDown className="font-bold" />
              )}
            </div>
          </li>}
          {usePermissions.privilege_management && subMenuState.privileges.open &&
            subMenuState.privileges.menuItems.map((item) => {
              return (
                <li
                  className={
                    (url.includes(item.link)
                      ? "bg-[#80B1F8] rounded-r-3xl"
                      : "") + ""
                  }
                >
                  <Link
                    to={item.link}
                    className="text-sm flex items-center gap-1 ps-10 py-2"
                  >
                    <item.icon className="inline" />
                    {item.name}
                  </Link>
                </li>
              );
            })}
          <li
            className="max-w-[100%] pe-2 hover:cursor-pointer hover:text-lg"
            onClick={handleLogout}
          >
            <div className="flex items-center gap-2 ps-4 py-3">
              <FiPower className="text-lg" />
              Logout
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
