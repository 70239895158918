import Dashboard from "../pages/dashboard/Dashboard";
import Users from "../pages/users/Users";
import UserDetails from "../pages/users/UserDetails";
import Gateways from "../pages/gateways/Gateways";
import GSettings from "../pages/gateways/GSettings";
import Merchants from "../pages/merchants/Merchants";
import MerchantTransaction from "../pages/merchants/MerchantTranaction";
import SupportTickets from "../pages/supporttickets/SupportTickets";
import Ticket from "../pages/supporttickets/Ticket";
import Transactions from "../pages/transactions/Transactions";
import Payout from "../pages/payout/Payout";
import RandP from "../pages/privileges/RandP/RandP"
import PaymentLink from "../pages/payin/PaymentLink";
import ProductCheckout from "../pages/payin/ProductCheckout";
import DMerchant from "../pages/merchants/DMerchant";
import Employees from "../pages/privileges/employees/Employees";
import { Route } from "../interfaces";
import Profile from "../pages/profile/Profile";

const Routes: Route[] = [
  {
    path: "profile",
    element: Profile,
  },
  {
    path: "dashboard",
    element: Dashboard,
    permission: "dashboard_management"
  },
  {
    path: "users",
    element: Users,
    permission: "user_management",
    subPages: [
      {
        path: 'details/:id',
        element: UserDetails
      }
    ]
  },
  {
    path: "payment-gateways",
    element: Gateways,
    permission: "financial_management",
    subPages: [
      {
        path: 'gsettings/:alias',
        element: GSettings
      }
    ]
  },
  {
    path: "merchants",
    element: Merchants,
    permission: "merchant_management",
    subPages: [
      // {
      //   path: 'client-secret',
      //   element: Merchants
      // },
      {
        path: 'details/:id',
        element: DMerchant
      },
      {
        path: 'transaction/:id',
        element: MerchantTransaction
      }
    ]
  },
  {
    path: "support-tickets",
    element: SupportTickets,
    permission: "support_management",
    subPages: [
      {
        path: 'ticket/:id',
        element: Ticket
      }
    ]
  },
  {
    path: "transactions",
    element: Transactions,
    permission: "financial_management"
  },
  {
    path: "payout",
    element: Payout,
    permission: "financial_management"
  },
  {
    path: "payment-link",
    element: PaymentLink,
    permission: "financial_management"
  },
  {
    path: "product-checkout",
    element: ProductCheckout,
    permission: "financial_management"
  },
  {
    path: "roles",
    element: RandP,
    permission: "privilege_management"
  },
  {
    path: "employees",
    element: Employees,
    permission: "privilege_management"
  },
]

export default Routes