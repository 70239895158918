import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { IoCloudUploadSharp } from "react-icons/io5";
import { AiOutlineCheck } from "react-icons/ai";
import { MdOutlineContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";

const PaymentLink = () => {
  interface Data {
    amount: string | number;
    initial_amount: string | number;
    description: string;
    currency: string;
    country: string;
    payer: {
      name: string;
      email: string;
      phone: string;
      document: string;
    };
    client_order_id: string;
  }

  const [data, setData] = useState<Data>({
    amount: "",
    description: "",
    initial_amount: "",
    currency: "USD",
    country: "USA",
    payer: {
      name: "",
      email: "",
      phone: "",
      document: "",
    },
    client_order_id: "",
  });

  const [X_API_KEY, setXAPIKEY] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [copied, setCopiedState] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(paymentLink);
    setCopiedState(true);
    setTimeout(() => {
      setCopiedState(false);
    }, 3000);
  };

  const submitForm = () => {
    if (
      !data.amount ||
      !data.initial_amount ||
      !data.payer.name ||
      !data.currency ||
      !data.country
    ) {
      console.log(data);
      toast("All fields are required!", {
        type: toast.TYPE.WARNING,
      });
      console.log(data);
      return;
    }
    setPaymentLink("");
    setBtnLoading(true);
    axios.defaults.headers.common["X-API-KEY"] = X_API_KEY;
    axios
      .post("dlocal/paymentLink", data)
      .then((res) => {
        console.log(res.data.data);
        setPaymentLink(res.data.data.url);
        toast("Link generated", {
          type: toast.TYPE.SUCCESS,
        });
        setBtnLoading(false);
        getXApiKey();
        setData((prev) => (prev = { ...data, client_order_id: makeid(8) }));
      })
      .catch((err) => {
        console.log(err);
        toast("An error occured, try again!", {
          type: toast.TYPE.ERROR,
        });
        setBtnLoading(false);
        getXApiKey();
        setData((prev) => (prev = { ...data, client_order_id: makeid(8) }));
      });
  };

  const getXApiKey = () => {
    axios
      .get("admin/merchants/97C52B3452")
      .then((res) => {
        // console.log()
        setXAPIKEY(res.data.data.merchant_appInfo.client_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function makeid(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  useEffect(() => {
    getXApiKey();
    setData((prev) => (prev = { ...data, client_order_id: makeid(8) }));
  }, []);

  return (
    <div>
      <div>
        <Header title="Payment Link" />
      </div>
      <div className="px-6 sm:px-11 pb-11">
        <div className="min-h-[400px]">
          <div>
            <h4 className="py-4 font-bold text-lg">Payment Link</h4>
          </div>
          <div>
            <div className="grid grid-cols-1 gap-4 text-sm">
              <div>
                <label htmlFor="amount" className="text-base">
                  Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  onChange={(e) => {
                    setData({
                      ...data,
                      amount: e.target.value,
                      initial_amount: e.target.value,
                    });
                  }}
                  className="w-full border-solid border-[1px] border-[#ABB3B7] focus:outline-cs-blue rounded-md placeholder:text-center ps-3 p-3"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-6">
              <div>
                <label htmlFor="currency" className="text-base">
                  Currency
                </label>
                <select
                  name="currency"
                  onChange={(e) =>
                    setData({ ...data, currency: e.target.value })
                  }
                  className="w-full border-solid border-[1px] focus:outline-cs-blue border-[#ABB3B7] rounded-md text-sm ps-3 p-3"
                >
                  <option value="NGN">NGN</option>
                  <option selected value="NGN">
                    USD
                  </option>
                </select>
              </div>

              <div>
                <label htmlFor="country" className="text-base">
                  Country
                </label>
                <select
                  name="country"
                  onChange={(e) =>
                    setData({ ...data, country: e.target.value })
                  }
                  className="w-full border-solid border-[1px] focus:outline-cs-blue border-[#ABB3B7] rounded-md text-sm ps-3 p-3"
                >
                  <option value="NG">NG</option>
                  <option selected value="US">
                    USA
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <h4 className="py-4 font-bold text-lg">Payer</h4>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
            <div>
              <label htmlFor="name" className="text-base">
                Name
              </label>
              <input
                type="text"
                name="name"
                onChange={(e) =>
                  setData(
                    (prev) =>
                      (prev = {
                        ...data,
                        payer: { ...data.payer, name: e.target.value },
                      })
                  )
                }
                className="w-full border-solid border-[1px] border-[#ABB3B7] focus:outline-cs-blue rounded-md placeholder:text-center ps-3 p-3"
              />
            </div>
            <div>
              <label htmlFor="email" className="text-base">
                Email
              </label>
              <input
                type="text"
                name="email"
                onChange={(e) =>
                  setData(
                    (prev) =>
                      (prev = {
                        ...data,
                        payer: { ...data.payer, email: e.target.value },
                      })
                  )
                }
                className="w-full border-solid border-[1px] border-[#ABB3B7] focus:outline-cs-blue rounded-md placeholder:text-center ps-3 p-3"
              />
            </div>
            <div>
              <label htmlFor="phone" className="text-base">
                Phone
              </label>
              <input
                type="text"
                name="phone"
                onChange={(e) =>
                  setData(
                    (prev) =>
                      (prev = {
                        ...data,
                        payer: { ...data.payer, phone: e.target.value },
                      })
                  )
                }
                className="w-full border-solid border-[1px] border-[#ABB3B7] focus:outline-cs-blue rounded-md placeholder:text-center ps-3 p-3"
              />
            </div>
            <div>
              <label htmlFor="image" className="text-base">
                Document
              </label>
              <input
                type="text"
                name="document"
                onChange={(e) =>
                  setData(
                    (prev) =>
                      (prev = {
                        ...data,
                        payer: { ...data.payer, document: e.target.value },
                      })
                  )
                }
                className="w-full border-solid border-[1px] border-[#ABB3B7] focus:outline-cs-blue rounded-md placeholder:text-center ps-3 p-3"
              />
            </div>
            <div>
              <label htmlFor="description" className="text-base">
                Description
              </label>
              <textarea
                name="description"
                id=""
                rows={3}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                className="w-full border-solid border-[1px] border-[#ABB3B7] focus:outline-cs-blue rounded-md placeholder:text-center ps-3 p-3"
              ></textarea>
            </div>
          </div>
          <div className="flex sm:justify-center pt-11">
            <div className="flex gap-4">
              <input
                type="text"
                name="link"
                readOnly
                value={paymentLink}
                className="w-full md:w-[350px] lg:w-[500px] border-solid border-[1px] focus:outline-cs-blue  border-[#ABB3B7] rounded-md ps-3 p-2"
              />
              <div
                className="flex items-center gap-1 text-cs-blue hover:cursor-pointer"
                onClick={copyToClipboard}
              >
                {copied ? <AiOutlineCheck /> : <MdOutlineContentCopy />}
                <p className="whitespace-pre">
                  {copied ? "Copied" : "Copy link"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center pt-6">
            <button
              disabled={btnLoading}
              className={`bg-cs-blue text-white px-[92px] py-[20px] text-center rounded-[10px] ${
                btnLoading ? "bg-[#adcae6]" : ""
              }`}
              onClick={submitForm}
            >
              {btnLoading ? "Loading..." : "Generate Link"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentLink;
