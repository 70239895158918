import React, { useState, useEffect } from "react";
import axios from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import { ImSpinner6 } from "react-icons/im";
import { AppUser } from "../../interfaces";
import { useAppDispatch } from "../../app/store";
import { setAppUser } from "../../app/features/auth/AppUser";

const Protected = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let appUserString = localStorage.getItem('appUser')
    let appUser: AppUser = appUserString ? JSON.parse(appUserString) : null
    if(!appUser){
      navigate('signin')
      localStorage.clear()
    }
    dispatch(setAppUser(appUser))
    try {
      axios
        .get("/admin/role")
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          navigate("signin");
        });
    } catch (err) {
      navigate("signin");
    }
  });

  if (loading) {
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center">
        <ImSpinner6 className="text-cs-blue w-[50px] h-[50px] animate-spin" />
      </div>
    );
  }

  return <Outlet />;
};

export default Protected;
