import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import Protected from "./components/auth/Protected";
import DashboarRoutes from "./routes/Routes";
import Login from "./pages/auth/Login";
import axios from "axios";
import PermissionProtected from "./components/auth/PermissionProtected";
// import { toast } from "react-toastify";

axios.defaults.baseURL = "https://apis.payfocuss.com/api/v1";
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // whatever you want to do with the error
//       toast(error.message, {
//         type: toast.TYPE.INFO,
//       });
//     console.log("Interceptor error")
//     throw error;
//   }
// );

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="signin" element={<Login />} />
          <Route element={<Protected />}>
            <Route element={<MainLayout />}>
              {DashboarRoutes.map((route, i) => {
                return (
                  <Fragment key={i}>
                    <Route element={<PermissionProtected route={route} />}>
                      <Route path={route.path} element={<route.element />} />
                      {route.subPages && route.subPages.length
                        ? route.subPages.map((sub, key) => {
                            return (
                              <Route
                                key={key}
                                path={route.path + "/" + sub.path}
                                element={<sub.element />}
                              />
                            );
                          })
                        : ""}
                    </Route>
                  </Fragment>
                );
              })}
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
