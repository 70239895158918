import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import ItemLoader from "../../../components/tableitemloader/ItemLoader";
import axios from "axios";
import { BiEditAlt, BiSearch } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye, AiOutlineClose } from "react-icons/ai";
import { MoonLoader, ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { PermissionName, Permissions } from "../../../interfaces";
import { Drawer } from "@material-tailwind/react";

const Employees = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  interface Employee {
    id: number;
    name: string;
    phone: string;
    email: string;
    admin_role_id: number;
    email_verified_at: string | null;
    type: string;
    status: number;
    created_at: string;
    updated_at: string;
    role: {
      id: number;
      name: string;
      module_access: Permissions;
      action_access: null;
      status: number;
      created_at: string | null;
      updated_at: string | null;
    };
  }
  interface Role {
    id: number;
    name: string;
    module_access: Permissions;
    action_access: null;
    status: number;
    created_at: null;
    updated_at: null;
  }
  const [roles, setRoles] = useState<Role[]>([]);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [dataLoading, setDataloading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  const [newEmployee, setNewEmployee] = useState({
    name: "",
    email: "",
    role_id: "",
    phone: "",
    password: "",
  });
  const [editDrawer, setEditDrawer] = useState(false);
  const [editEmployee, setEditEmployee] = useState({
    id: 0,
    name: "",
    email: "",
    role_id: 0,
    phone: "",
    password: "",
  });

  const getRoles = () => {
    axios
      .get("/admin/employee/role")
      .then((res) => {
        setRolesLoading(false);
        setRoles((prev) => (prev = res.data.data));
      })
      .catch((err) => {
        console.log(err);
        setRolesLoading(false);
      });
  };

  const createNewEmployee = () => {
    setAddLoading(true);
    axios
      .post("/admin/employee/create", newEmployee)
      .then((res) => {
        console.log(res);
        toast("Employee Added Successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        setAddLoading(false);
        getEmployeeList();
      })
      .catch((err) => {
        console.log(err);
        setAddLoading(false);
      });
  };
  const deleteEmployee = (id: string | number) => {
    setStatusLoading(true);
    axios
      .get(`/admin/employee/delete/${id}`)
      .then((res) => {
        setStatusLoading(false);
        toast(res.data.message, {
          type: toast.TYPE.SUCCESS,
        });
        getEmployeeList();
      })
      .catch((err) => {
        console.log(err);
        setStatusLoading(false);
        toast(err.response?.data?.message, {
          type: toast.TYPE.INFO,
        });
        if (!err.response?.data?.message) {
          toast(err.message, {
            type: toast.TYPE.INFO,
          });
        }
      });
  };

  const changeEmployeeStatus = (id: number, empStatus: number) => {
    setStatusLoading(true);
    const data = {
      status: empStatus === 1 ? 0 : 1,
      admin_id: id,
    };
    axios
      .post("/admin/employee/status", data)
      .then((res) => {
        getEmployeeList();
        setStatusLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setStatusLoading(false);
        toast(err.response?.data?.message, {
          type: toast.TYPE.INFO,
        });
        if (!err.response?.data?.message) {
          toast(err.message, {
            type: toast.TYPE.INFO,
          });
        }
      });
  };

  const getEmployeeList = () => {
    axios
      .get("/admin/employee/list")
      .then((res) => {
        setEmployeeData((prev) => (prev = res.data.data.data));
        setDataloading(false);
        getRoles();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openDrawer = (emp: Employee) => {
    setEditDrawer(true);
    setEditEmployee(prev => prev = {id: emp.id, name: emp.name, email: emp.email, role_id: emp.role.id, phone: emp.phone, password: ''})
  };
  const closeDrawer = () => {
    setEditDrawer(false);
    setEditEmployee(
      (prev) =>
        (prev = {
          id: 0,
          name: "",
          email: "",
          role_id: 0,
          phone: "",
          password: "",
        })
    );
    getEmployeeList();
  };
  const updateEmployeeSubmit = () => {
    console.log(editEmployee)
    let data = Object.fromEntries(
      Object.entries(editEmployee).filter(([_, v]) => v != "")
    );
    console.log(data)
    axios.post(`/admin/employee/update/${editEmployee.id}`, data).then(res => {
      console.log(res.data.data)
      toast(res.data.message, {
        type: toast.TYPE.SUCCESS,
      });
      closeDrawer();
    }).catch(err => {
      console.log(err)
      toast(err.response?.data?.message, {
          type: toast.TYPE.INFO,
        });
        if (!err.response?.data?.message) {
          toast(err.message, {
            type: toast.TYPE.INFO,
          });
        }
    })
  }
  useEffect(() => {
    getEmployeeList();
  }, []);

  return (
    <div>
      <div>
        <Header title="Employees" />
      </div>
      <div className="px-6 sm:px-11 pb-11">
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center ">
            <p className="py-4 font-bold text-xl">Add Employee</p>
          </div>

          <div className="card-shadow rounded-[10px] p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="text-sm">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={newEmployee.name}
                  onChange={(e) =>
                    setNewEmployee(
                      (prev) => (prev = { ...prev, name: e.target.value })
                    )
                  }
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="email" className="text-sm">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  onChange={(e) =>
                    setNewEmployee(
                      (prev) => (prev = { ...prev, email: e.target.value })
                    )
                  }
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="role_id" className="text-sm">
                  Role ID
                </label>
                <select
                  name="role_id"
                  id=""
                  onChange={(e) =>
                    setNewEmployee(
                      (prev) => (prev = { ...prev, role_id: e.target.value })
                    )
                  }
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                >
                  <option value="" selected>
                    Select Role
                  </option>
                  {rolesLoading ? (
                    <option>
                      <MoonLoader />
                    </option>
                  ) : (
                    roles.map((role, idx) => {
                      return (
                        <option key={idx} value={role.id}>
                          {role.name}
                        </option>
                      );
                    })
                  )}
                </select>
              </div>
              <div>
                <label htmlFor="phone" className="text-sm">
                  Phone
                </label>
                <input
                  type="number"
                  name="phone"
                  onChange={(e) =>
                    setNewEmployee(
                      (prev) => (prev = { ...prev, phone: e.target.value })
                    )
                  }
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
              <div>
                <label htmlFor="password" className="text-sm">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  onChange={(e) =>
                    setNewEmployee(
                      (prev) => (prev = { ...prev, password: e.target.value })
                    )
                  }
                  className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-3xl placeholder:text-center ps-3 p-1"
                />
              </div>
            </div>
            <div className="flex justify-center py-4">
              <button
                className="w-[76px] bg-cs-blue text-white py-1 rounded"
                onClick={createNewEmployee}
              >
                {addLoading ? <ClipLoader color="#fff" size={15} /> : "Add"}
              </button>
            </div>
          </div>
          <div>
            <div className="flex flex-col sm:flex-row justify-between items-center py-4">
              <div className="flex items-center gap-2">
                <p className="py-4 font-bold text-base">List of Employees</p>
                <div className="w-[20px] h-[20px] rounded-full bg-cs-blue text-white flex items-center justify-center text-xs">
                  {employeeData.length}
                </div>
              </div>

              <div className="flex gap-1">
                <div className="relative inline-block">
                  <input
                    className="sm:w-[200px] md:w-[250px] lg:w-[350px] outline-none border-solid border-2 rounded-3xl placeholder:text-center ps-4 py-1 "
                    type="text"
                    placeholder="search"
                  />
                  <div className="h-full w-[10%] absolute right-0 top-0 flex items-center">
                    <BiSearch className="w-[20px] h-[20px]" />
                  </div>
                </div>
                <select
                  className="w-full rounded border-solid border-[1px] text-sm px-4"
                  placeholder="Export"
                  id=""
                >
                  <option value="" selected disabled>
                    Export
                  </option>
                </select>
              </div>
            </div>
            <div>
              <div className="overflow-auto lg:overflow-visible">
                <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                  <thead className="bg-[#80B1F8]">
                    <tr className="">
                      <th className="p-2">Id</th>
                      <th className="p-2">Name</th>
                      <th className="p-2">Role</th>
                      <th className="p-2">Status</th>
                      <th className="p-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataLoading
                      ? Array(4)
                          .fill(1)
                          .map((a, row) => (
                            <tr key={row} className="bg-[#fff] card-shadow">
                              {Array(5)
                                .fill(1)
                                .map((a, col) => (
                                  <td key={col} className="p-2">
                                    <ItemLoader />
                                  </td>
                                ))}
                            </tr>
                          ))
                      : null}
                    {employeeData.map((emp, idx) => {
                      return (
                        <tr key={idx} className="bg-[#fff] card-shadow">
                          <td>{emp.id}</td>
                          <td>{emp?.name}</td>
                          <td>{emp.role?.name}</td>
                          <td>
                            {emp.status === 1 ? (
                              <p
                                onClick={() =>
                                  changeEmployeeStatus(emp.id, emp.status)
                                }
                                className="inline text-white bg-cs-blue py-1 px-4 rounded hover:cursor-pointer"
                              >
                                Active
                              </p>
                            ) : (
                              <p
                                onClick={() =>
                                  changeEmployeeStatus(emp.id, emp.status)
                                }
                                className="inline text-white bg-red-500 py-1 px-4 rounded hover:cursor-pointer"
                              >
                                Inactive
                              </p>
                            )}
                          </td>
                          <td className="p-2 ">
                            <div className="flex justify-center gap-2">
                              {/* <AiOutlineEye className="text-xl hover:text-cs-blue" /> */}
                              <BiEditAlt
                                onClick={()=>openDrawer(emp)}
                                className="text-xl hover:text-[#E87001]"
                              />
                              <RiDeleteBinLine
                                onClick={() => deleteEmployee(emp.id)}
                                className="text-xl hover:text-[#C70202]"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={statusLoading}
          ariaHideApp={false}
          style={customStyles}
        >
          <div className="flex justify-center items-center">
            <MoonLoader color="#0D6EFD" />
          </div>
        </ReactModal>
        {editDrawer && (
          <div className="fixed h-screen min-w-[300px] lg:w-[423px] overflow-y-auto">
            <Drawer
              size={300}
              open={editDrawer}
              className="p-4"
              placement="right"
            >
              <div className="flex justify-end">
                <p
                  className="underline text-cs-blue font-bold cursor-pointer"
                  onClick={closeDrawer}
                >
                  <AiOutlineClose />
                </p>
              </div>
              <h4 className="py-4">Information on {editEmployee.name}</h4>
              <div className="flex flex-col gap-4">
                <div>
                  <label htmlFor="name" className="text-sm">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={editEmployee.name}
                    onChange={(e) =>
                      setEditEmployee(
                        (prev) => (prev = { ...prev, name: e.target.value })
                      )
                    }
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="text-sm">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={editEmployee.email}
                    onChange={(e) =>
                      setEditEmployee(
                        (prev) => (prev = { ...prev, email: e.target.value })
                      )
                    }
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="text-sm">
                    phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={editEmployee.phone}
                    onChange={(e) =>
                      setEditEmployee(
                        (prev) => (prev = { ...prev, phone: e.target.value })
                      )
                    }
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                  />
                </div>
                <div>
                  <label htmlFor="role_id" className="text-sm">
                    Role ID
                  </label>
                  <select
                    name="role_id"
                    value={editEmployee.role_id}
                    onChange={(e) =>
                      setEditEmployee(
                        (prev) => (prev = { ...prev, role_id: Number(e.target.value) })
                      )
                    }
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                  >
                    <option value="" selected>
                      Select Role
                    </option>
                    {rolesLoading ? (
                      <option>
                        <MoonLoader />
                      </option>
                    ) : (
                      roles.map((role, idx) => {
                        return (
                          <option key={idx} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })
                    )}
                  </select>
                </div>
                <div>
                  <label htmlFor="password" className="text-sm">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={editEmployee.password}
                    onChange={(e) =>
                      setEditEmployee(
                        (prev) => (prev = { ...prev, password: e.target.value })
                      )
                    }
                    className="w-full border-solid border-[1px] border-[#ABB3B7] outline-none focus:border-[#0D6EFD] rounded-lg placeholder:text-center ps-3 p-1"
                  />
                </div>
                <div className="flex justify-center pt-11">
                  <button className="h-[40px] bg-cs-blue text-sm text-white px-6 rounded-lg" onClick={updateEmployeeSubmit}>
                    Save
                  </button>
                </div>
              </div>
            </Drawer>
          </div>
        )}
      </div>
    </div>
  );
};

export default Employees;
