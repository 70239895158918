import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Ptag from "./Ptag";
import { BiEditAlt, BiSearch } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import ItemLoader from "../../../components/tableitemloader/ItemLoader";
import { ClipLoader, MoonLoader } from "react-spinners";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import ModuleComponent from "./ModuleComponent";
import { PermissionName, Permissions } from "../../../interfaces";
// import { Drawer } from "@material-tailwind/react";

const Randp = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  interface Role {
    id: number;
    name: string;
    module_access: Permissions;
    action_access: null;
    status: number;
    created_at: null;
    updated_at: null;
  }
  // const navigate = useNavigate();
  const [permissions, setPermissions] = useState<Permissions>(
    {} as Permissions
  );

  const [dataLoading, setDataloading] = useState(true);
  const [roles, setRoles] = useState<Role[]>([]);

  const [addModule, setAddModule] = useState<Permissions>({} as Permissions);
  const [newRole, setNewRole] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  // const [editDrawer, setEditDrawer] = useState(false);
  // const [editRole, setEditRole] = useState<Role>({} as Role);

  const clickPermission = (
    name: PermissionName,
    value: string,
    init: boolean
  ): void => {
    const record: Permissions = { [name]: value } as Permissions;
    if (Object.keys(addModule).includes(name) && init) {
      const temp = addModule;
      delete temp[name];
      setAddModule((prev) => (prev = { ...temp }));
    } else {
      setAddModule((prev) => (prev = { ...prev, ...record }));
    }
  };
  const submitNewRole = () => {
    if (!Object.keys(addModule).length || !newRole) {
      toast("Invalid Role, Role name and Permission must not be empty!", {
        type: toast.TYPE.INFO,
      });
      return;
    }
    setSubmitLoading(true);

    const data = {
      name: newRole,
      modules: addModule,
    };
    axios
      .post("/admin/employee/role", data)
      .then((res) => {
        setSubmitLoading(false);
        toast("Success! Role Created", {
          type: toast.TYPE.SUCCESS,
        });
        getRoles();
      })
      .catch((err) => {
        setSubmitLoading(false);
        toast(err.response?.data?.message, {
          type: toast.TYPE.INFO,
        });
        if (!err.response?.data?.message) {
          toast(err.message, {
            type: toast.TYPE.INFO,
          });
        }
      });
  };

  const getRoles = () => {
    axios
      .get("/admin/employee/role")
      .then((res) => {
        setDataloading(false);
        setRoles((prev) => (prev = res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteRole = (id: number) => {
    setStatusLoading(true);
    axios
      .get(`/admin/employee/role/delete/${id}`)
      .then((res) => {
        toast(res.data.message, {
          type: toast.TYPE.SUCCESS,
        });
        setStatusLoading(false);
        getRoles();
      })
      .catch((err) => {
        setStatusLoading(false);
        toast(err.response?.data?.message, {
          type: toast.TYPE.INFO,
        });
        if (!err.response?.data?.message) {
          toast(err.message, {
            type: toast.TYPE.INFO,
          });
        }
      });
  };

  useEffect(() => {
    axios
      .get("/admin/employee/permission")
      .then((res) => {
        // console.log(res.data.data[0]);
        setPermissions(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    getRoles();
  }, []);

  // const openDrawer = (role: Role) => {
  //   setEditRole(role);
  //   setEditDrawer(true);
  // };
  // const closeDrawer = () => {
  //   setEditDrawer(false);
  //   setEditRole({} as Role);
  // };
  // const clickEditPermission = (
  //   name: PermissionName,
  //   value: string,
  //   init: boolean
  // ) => {};
  return (
    <div>
      <div>
        <Header title="Role & Permission" />
      </div>
      <div className="px-6 sm:px-11 pb-11">
        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center ">
            <p className="py-4 font-bold text-xl">Employee Role Setup</p>
          </div>

          <div className="card-shadow rounded-[10px] p-4">
            <p className="text-lg pb-4">Role Name</p>
            <div className="grid sm:grid-cols-2">
              <input
                type="text"
                name="role"
                value={newRole}
                onChange={(e) => setNewRole(e.target.value)}
                placeholder="Ex; Management"
                className="w-full text-sm border-solid border-[1px] outline-none border-[#ABB3B7] rounded-3xl placeholder: ps-4 p-2"
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-2 py-4">
              <div className="whitespace-pre">
                <p>Module Permissions:</p>
              </div>
              <div className="flex flex-wrap gap-2">
                {Object.keys(permissions).map((key, idx) => {
                  return (
                    <div key={idx}>
                      <Ptag
                        clickPermission={clickPermission}
                        name={key as keyof Permissions}
                        value={permissions[key as keyof Permissions]}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full flex justify-end pt-6">
              <button
                disabled={submitLoading}
                className={`bg-cs-blue w-[80px] text-white text-center py-1 text-sm rounded`}
                onClick={submitNewRole}
              >
                {submitLoading ? (
                  <ClipLoader color="#fff" size={15} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
          <div>
            <div className="flex flex-col sm:flex-row justify-between items-center py-4">
              <div className="flex items-center gap-2">
                <p className="py-4 font-bold text-base">Employee roles</p>
                <div className="w-[20px] h-[20px] rounded-full bg-cs-blue text-white flex items-center justify-center text-xs">
                  {roles.length}
                </div>
              </div>

              <div className="flex gap-1">
                <div className="relative inline-block">
                  <input
                    className="sm:w-[200px] md:w-[250px] lg:w-[350px] outline-none border-solid border-2 rounded-3xl placeholder:text-center ps-4 py-1 "
                    type="text"
                    placeholder="search"
                  />
                  <div className="h-full w-[10%] absolute right-0 top-0 flex items-center">
                    <BiSearch className="w-[20px] h-[20px]" />
                  </div>
                </div>
                <select
                  className="w-full rounded border-solid border-[1px] text-sm px-4"
                  placeholder="Export"
                  id=""
                >
                  <option value="" selected disabled>
                    Export
                  </option>
                </select>
              </div>
            </div>
            <div>
              <div className="overflow-auto lg:overflow-visible">
                <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                  <thead className="bg-[#80B1F8]">
                    <tr className="">
                      <th className="p-2">Id</th>
                      <th className="p-2">Role</th>
                      <th className="p-2">Modules</th>
                      <th className="p-2">Status</th>
                      <th className="p-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataLoading
                      ? Array(4)
                          .fill(1)
                          .map((a, row) => (
                            <tr key={row} className="bg-[#fff] card-shadow">
                              {Array(5)
                                .fill(1)
                                .map((a, col) => (
                                  <td key={col} className="p-2">
                                    <ItemLoader />
                                  </td>
                                ))}
                            </tr>
                          ))
                      : null}
                    {roles.map((value, idx) => {
                      return (
                        <tr key={idx} className="bg-[#fff] card-shadow">
                          <td className="p-2">
                            <div className="">{value.id}</div>
                          </td>
                          <td className="p-2">{value.name}</td>
                          <td className="p-2">
                            <ModuleComponent
                              module_access={value.module_access}
                            />
                          </td>
                          <td className="p-2">
                            <p
                              className={`px-2 py-1 rounded bg-cs-blue hover:cursor-pointer text-white ${
                                !(value.status === 1) ? "bg-red-500" : ""
                              }`}
                            >
                              {value.status === 1 ? "Enabled" : "Disabled"}
                            </p>
                          </td>
                          <td className="p-2 ">
                            <div className="flex justify-center gap-2">
                              {/* <AiOutlineEye className="text-xl hover:text-cs-blue" /> */}
                              {/* <BiEditAlt
                                onClick={() => openDrawer(value)}
                                className="text-xl hover:text-[#E87001]"
                              /> */}
                              <RiDeleteBinLine
                                className="text-xl hover:text-[#C70202]"
                                onClick={() => deleteRole(value.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={statusLoading}
            ariaHideApp={false}
            style={customStyles}
          >
            <div className="flex justify-center items-center">
              <MoonLoader color="#0D6EFD" />
            </div>
          </ReactModal>
        </div>
      </div>
    </div>
  );
};

export default Randp;
