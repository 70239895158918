import React from "react";
import Header from "../../components/Header";
import { BiSearch } from 'react-icons/bi'

const Payout = () => {
  return (
    <div>
      <div>
        <Header title="Payout" />
      </div>
      <div className="px-11 pb-11">
        <div>
        <div className="flex flex-col sm:flex-row justify-between items-center ">
            <p className="py-4 font-bold text-xl">Details</p>
            <div className="relative inline-block">
              <input
                className="sm:w-[250px] md:w-[300px] lg:w-[390px] border-solid border-2 rounded-3xl placeholder:text-center"
                type="text"
                placeholder="search"
              />
              <BiSearch className="absolute right-3 top-[15%]" />
            </div>
          </div>
          <div>
            <div className="overflow-auto lg:overflow-visible ">
              <table className="b-table table w-[100%] border-separate border-spacing-y-2 space-y-6 text-sm text-center">
                <thead className="bg-[#80B1F8]">
                  <tr className="">
                    <th className="p-2">S/N</th>
                    <th className="p-2">Account Name</th>
                    <th className="p-2">Account Number</th>
                    <th className="p-2">Bank</th>
                    <th className="p-2">Amount</th>
                    <th className="p-2">Status</th>
                    <th className="p-2">Transaction</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-[#fff] shadow-xl">
                    <td className="p-2 font-bold">
                      <div className="">1</div>
                    </td>
                    <td className="p-2 font-bold">James Peter</td>
                    <td className="p-2 font-bold">2275641221</td>
                    <td className="p-2 font-bold">Zenith Bank</td>
                    <td className="p-2 font-bold">Amount</td>
                    <td className="p-2">
                      <p className="px-2 py-1 text-white bg-green-600 rounded">Approved</p>
                    </td>
                    <td className="p-2">
                      <p className="px-2 py-1 text-white bg-cs-blue rounded">Submit Payment</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payout;
